import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
	forms: {
		level8 : Array(10),
		level67 : Array(10)
	}
}

const simulatorSlice = createSlice({
	name: 'simulator',
	initialState,
	reducers: {
		setFormValue: (state, action) => {
			const { index, form, value, paste } = action.payload
			state.forms[form] = _.map(state.forms[form], (fr, frIndex) => index === frIndex ? value : fr)
			if(paste) {
				delete state.copiedCourse
			}
		},
		copyCourse: (state, action) => {
			state.copiedCourse = action.payload
		}
	}
})

export const {
	setFormValue,
	copyCourse
} = simulatorSlice.actions
export const getForm = (state, form) => {
	return _.get(state.simulatorSlice, ['forms', form])
}
export const getCopiedCourse = state => _.get(state.simulatorSlice, ['copiedCourse'])
export default simulatorSlice.reducer