import { Button, Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { isModalOpen, closeModal } from '../../app/appSlice'
import { CircleX } from 'akar-icons';  


function GetStartedModal() {

    const modalOpen = useSelector(isModalOpen('get-started'))
	const dispatch = useDispatch()

	return (
		<Modal 
			show={modalOpen} 
			onHide={() => dispatch(closeModal())}
			centered	
			size="lg"		
		>
			<Modal.Header>
				<div className="close-btn-holder">
					<button 
						className='btn btn-close' 
						onClick={() => {dispatch(closeModal())}}
					>
						<CircleX size="50" strokeWidth="1"/>
					</button>
				</div>
				<Modal.Title className="gs-title">
					Getting Started on Our Website
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>              
                <ol>
                    <li><strong>Sign up:</strong> Create an account for personalised access</li>
                    <li><strong>Set up profile:</strong> Provide details to tailor content</li>
                    <li>Click on Points Calculator on the  wheel to begin your career exploration journey</li>                    
                </ol>			
			</Modal.Body>
			<Modal.Footer>				
				<Button variant="secondary" onClick={() => dispatch(closeModal())}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default GetStartedModal