import { Container, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import { selectLocation, getCurrentPage, setCurrentPage, setScreenWidth, resetTabsEnabled } from '../features/dashboard/dashboardSlice'
import { useGetUserCareersQuery } from '../features/user/userAPI'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import Select from 'react-select'
import { useSetStepsMutation } from '../features/user/userAPI' 
import { getCurrentUser, updateStepsCompleted } from '../features/auth/authSlice'
import { Link } from 'react-router-dom'
import Pagination from 'react-bootstrap/Pagination';
import CareerTabs from '../features/dashboard/CareerTabs'

const Dashboard = () => {   
    const dispatch = useDispatch() 
    const currentPage = useSelector(getCurrentPage)
    const userCareers = _.get(useGetUserCareersQuery({page: currentPage, per_page: 4}), ['data', 'careers'])
    const loadingUserCareers = _.get(useGetUserCareersQuery({page: currentPage, per_page: 4}), ['isLoading'])
    const pageData = _.pick(_.get(useGetUserCareersQuery({page: currentPage, per_page: 4}), ['data']), ['page', 'total'])
    const user = useSelector(getCurrentUser)
    const [ setStepsCompleted ] = useSetStepsMutation();    
    const calculatorNotVisited = !_.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[0] ||
                                    _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[0] === '0'

    const profilerNotVisited = !_.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[1] || 
                                    _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[1] === '0' 

    const selectStyles = {
        control: styles => ({ ...styles}),
        menuList: styles => {return {...styles, 'padding': 0}},
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {          
            return {
                ...styles,                 
                'backgroundColor': '#16161E;',   
                ':hover' : { 
                    backgroundColor: '#7157F8'              
                },                          
            };
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })        
    };  

    useEffect(() => {
        function handleResize() {           
            dispatch(setScreenWidth({screenWidth: window.innerWidth}))            
        }
        window.addEventListener('resize', handleResize)
        dispatch(setScreenWidth({screenWidth: window.innerWidth}))        
    }, [dispatch])   


    useEffect(() => {    
        if(user && userCareers) {
            const bitSet = _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[2] 
            if(!bitSet || bitSet === '0') {
                setStepsCompleted({step_completed: 2})
                .then(result => dispatch(updateStepsCompleted({steps_completed: _.get(result, ['data', 'steps_completed'])})))
                .catch(error => console.log(error))
            }
            dispatch(resetTabsEnabled({size: _.size(userCareers)}))
        }
    }, [user, dispatch, setStepsCompleted, userCareers])

    return (
        <Container fluid className="muvr-content dashboard">
            <Row className="top-row muvr-row">
                <Col xs={12} sm={{offset: 1, span: 10}}>
                    <div className="title">
                        <div className="main-title">Student Dashboard</div>
                        <div className="title-desc">Access your personalised dashboard to manage your career exploration journey</div>
                    </div>                   
                </Col>                
            </Row>     
            {
                _.isEmpty(userCareers) || calculatorNotVisited || profilerNotVisited ? 
                    <Row className="muvr-row">
                        <Col xs={12}>
                            <div className="no-careers">
                                {
                                    calculatorNotVisited && (
                                        <div className="no-step centered-content">
                                            You have not logged exam results. Please visit the Calculator
                                            <Button 
                                                as={Link} 
                                                to="/caopointscalculator"
                                                variant="outline-primary"                                                
                                                size="lg" 
                                            >
                                                Calculator
                                            </Button>
                                        </div>                                        
                                    )
                                }
                                {
                                    ! calculatorNotVisited && profilerNotVisited && (
                                        <div className="no-step centered-content">
                                            You have not completed the Interest Profile. Please visit the Interest Profiler
                                             <Button 
                                                as={Link} 
                                                to="/interest-profiler"
                                                variant="outline-primary"  
                                                size="lg"                                            
                                            >
                                                Interest Profiler
                                            </Button>
                                        </div>
                                    )
                                }
                                {
                                    !calculatorNotVisited && !profilerNotVisited && _.isEmpty(userCareers) && !loadingUserCareers && (
                                        <div className="no-step centered-content">
                                            You have not selected any careers. Please visit the Interest Profiler
                                            <Button 
                                                as={Link} 
                                                to="/interest-profiler"
                                                variant="outline-primary"   
                                                size="lg"                                          
                                            >
                                                Interest Profiler
                                            </Button>
                                        </div>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                    :
                    <>
                        <Row>
                            <div className="background-gradient" />    
                                <div className="careers">
                                    <div className="dash-filters">
                                        <Select  
                                            menuPortalTarget={document.body}           
                                            className="basic-single"
                                            classNamePrefix="muvr-select"                
                                            isSearchable={false}
                                            onChange={ selected => dispatch(selectLocation(selected))}
                                            options={_.map(['Dublin', 'Leinster', 'Munster', 'Connaught', 'Ulster'], l => {return {value:  l, label: l}})}  
                                            placeholder="Filter Location"
                                            styles={selectStyles}                                        
                                            isMulti
                                        /> 
                                    </div>                  
                                    {
                                        userCareers.map((career, index) => {                                                 
                                            return (
                                                <Row key={career.id} className="top-row muvr-row">
                                                    <Col xs={12} className="centered-content">
                                                        <CareerTabs career={career} career_index={index}/>                                                    
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    } 
                                    {
                                        pageData.total > 4 &&
                                            <Row>
                                                <Col className="centered-content">
                                                    <Pagination size="lg">
                                                        <Pagination.First 
                                                            onClick={() => dispatch(setCurrentPage({page: 1}))}
                                                            disabled={currentPage === 1}
                                                        />
                                                        <Pagination.Prev 
                                                            onClick={() => dispatch(setCurrentPage({page: currentPage - 1}))}
                                                            disabled={currentPage === 1}
                                                        />                                           
                                                        {
                                                            _.map(_.range(1, (pageData.total / 4) + 1), pageNum => {
                                                                return (
                                                                    <Pagination.Item 
                                                                        key={pageNum} 
                                                                        active={pageNum === currentPage}
                                                                        onClick={() => dispatch(setCurrentPage({page: pageNum}))}
                                                                    >
                                                                        {pageNum}
                                                                    </Pagination.Item>   
                                                                ) 
                                                            })
                                                        }                                           
                                                        <Pagination.Next 
                                                            onClick={() => dispatch(setCurrentPage({page: currentPage + 1}))}
                                                            disabled={currentPage === Math.ceil(pageData.total / 4)}
                                                        />
                                                        <Pagination.Last  
                                                            onClick={() => dispatch(setCurrentPage({page: Math.ceil(pageData.total / 4)}))}                                                            
                                                            disabled={currentPage === Math.ceil(pageData.total / 4)}
                                                        />
                                                    </Pagination>      
                                                </Col>
                                            </Row>  
                                    }
                            </div> 
                        </Row>  
                        <Row className="muvr-row">
                            <Col className="centered-content">
                                <div className="muvr-step-link">
                                    <div className="step-link">
                                        <div className="gradient-text">
                                            Continue to CAO Simulator
                                        </div>
                                        <Button as={Link} to="/cao-simulator" size="lg" variant="outline-primary">
                                            CAO Simulator
                                        </Button>
                                    </div>        
                                </div>                                    
                            </Col>
                        </Row>    
                        <Row className="muvr-row">
                            <Col className="centered-content">
                                <div className="muvr-step-link">
                                    <div className="step-link">
                                        <div className="gradient-text">
                                            Continue to VR Career Tours
                                        </div>
                                        <Button as={Link} to="/career-experience" size="lg" variant="outline-primary">
                                            VR Career Experience
                                        </Button>
                                    </div>        
                                </div>                                    
                            </Col>
                        </Row>    
                    </>
            }
        </Container>
    )
}

export default Dashboard