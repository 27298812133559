import { apiSlice } from "../../app/apiSlice";

export const calculatorAPI = apiSlice.injectEndpoints({   
    tagTypes: ['ExamResult', 'CareerCourses'], 
    endpoints: (builder) => ({      
        getSeasons: builder.query({
            query: () => 'season', 
            method: 'GET'      
        }),
        getSubjects: builder.query({
            query: () => 'subject', 
            method: 'GET'      
        }),
        getGrades: builder.query({
            query: () => 'grade', 
            method: 'GET'      
        }),
        getLevels: builder.query({
            query: () => 'level', 
            method: 'GET'      
        }),
        getExamResults: builder.query({
            query: data => 'exam_result',
            method: 'GET',
            providesTags: ['ExamResult']  
        }),
        updateExamResult:  builder.mutation({
            query: examResult => ({
                url: 'exam_result',
                method: 'POST',              
                body: examResult,           
            }),
            invalidatesTags: ['ExamResult', 'CareerCourses']
        })       
    })
})

export const  {     
    useGetSeasonsQuery,
    useGetSubjectsQuery,  
    useGetExamResultsQuery,
    useUpdateExamResultMutation,
    useGetGradesQuery,
    useGetLevelsQuery
} = calculatorAPI