import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslation, getTheme } from '../app/appSlice'
import twitterLogo from '../assets/img/socialLogos/twitter.svg'
import facebookLogo from '../assets/img/socialLogos/facebook.png'
import instagramLogo from '../assets/img/socialLogos/instagram.png'
import tikTocLogoWhite from '../assets/img/socialLogos/tiktokwhite.svg'
import tikTocLogoBlack from '../assets/img/socialLogos/tiktokblack.svg'

const Footer = () => {
    const footerLabel = useSelector(getTranslation('footer.label'))   
    const theme = useSelector(getTheme)

    return (
        <div className="footer">
            <div className="social-icons">
                <img src={twitterLogo} alt="twitter logo"/>
                <img src={facebookLogo} alt="facebook logo"/>
                <img src={instagramLogo} alt="instagram logo"/>
                <img src={theme === 'dark' ? tikTocLogoBlack : tikTocLogoWhite} alt="toktoc logo"/>
            </div>
            
            <div className="footer-links">
                <Link to="about" className="gradient-text">About</Link>
                <Link to="contact" className="gradient-text">Contact Us</Link>
            </div>       
            <div className="copyright">
                {footerLabel}
            </div>     
        </div>
    )
}

export default Footer