import { apiSlice } from "../../app/apiSlice";

export const userAPI = apiSlice.injectEndpoints({    
    tagTypes: ['UserCareer', 'UserCourse'],
    endpoints: builder => ({
        getCurrentUser: builder.query({
            query: () => 
                'user/current',
                method: 'GET'       
        }),
        getSimpleUserCareersList: builder.query({
            query: () => 
                'career/user/list',
                method: 'GET',
                providesTags: ['UserCareer'] 
        }),    
        getUserCareers: builder.query({
            query: req => {
                const { page, per_page } = req ? req : {}
                return {
                    url: 'career/user',
                    method: 'GET',
                    params: { page, per_page },                    
                }
            },
            providesTags: ['UserCareer']    
        }),    
        updateUserCareers:  builder.mutation({
            query: payload => ({
                url: 'career/user',
                method: 'POST',              
                body: payload,           
            }),
            invalidatesTags: ['UserCareer']
        }),
        getUserCourses: builder.query({
            query: () => 
                'course/user',
                method: 'GET',
                providesTags: ['UserCourse'] 
        }),    
        updateUserCourses:  builder.mutation({
            query: payload => ({
                url: 'course/user',
                method: 'POST',              
                body: payload,           
            }),
            invalidatesTags: ['UserCourse']
        }),
        getSteps: builder.query({
            query: () => ({
                url: 'user/step',
                method: 'GET'              
            })
        }),
        setSteps: builder.mutation({
            query: req => {
                const { step_completed } = req
                return {
                    url: 'user/step', 
                    method: 'POST',
                    body: { step_completed }    
                }
            }               
        }),        
    })
})

export const  { useGetStepsQuery, useGetSimpleUserCareersListQuery, useSetStepsMutation, useGetCurrentUserQuery, useGetUserCareersQuery, useUpdateUserCareersMutation,  useGetUserCoursesQuery, useUpdateUserCoursesMutation } = userAPI