import { Pagination } from 'react-bootstrap'
import _ from 'lodash'
import { getCoursesPerPage, setTabEnabled, getSelectedLocations, getTabsEnabled } from './dashboardSlice'
import { useGetCoursesQuery } from './dashboardAPI'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import CourseCard from './cards/CourseCard'
import { TiArrowBack, TiArrowForward } from 'react-icons/ti'
import { useSwipeable } from 'react-swipeable'

const CoursesTabContent = props => {   
    const { career, courseLevel } = props
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1);
    const coursesPerPage = useSelector(getCoursesPerPage)
    const locations = useSelector(getSelectedLocations)
    const tabsEnabled = useSelector(state => getTabsEnabled(state, career.career_index))

    const req = _.assign(
        {}, 
        {
            career_id: career.id, 
            page: currentPage, 
            per_page: coursesPerPage, 
            locations: _.join(_.map(locations, location => location.value))
        }, 
        _.pick(courseLevel, ['level', 'is_apprenticeship', 'is_direct', 'is_international'])
    )
    const coursesData = _.get(useGetCoursesQuery(req, {skip: !coursesPerPage}), ['data'])  
    const courses = _.get(coursesData, ['courses'])
    const pageData = _.pick(coursesData, ['page', 'total'])

    const swipeHandlers = useSwipeable({        
        onSwipedLeft: eventData => {
            if(currentPage !== 1) {
                setCurrentPage(currentPage - 1)
            }
        },
        onSwipedRight: eventData => {
            if(currentPage < Math.ceil(pageData.total / coursesPerPage)) {
                setCurrentPage(currentPage + 1)
            }
        },        
    });
       
    useEffect(() => {         
       window.addEventListener('resize', () => {           
            if(currentPage > Math.ceil(pageData.total / coursesPerPage)) {              
                setCurrentPage(Math.ceil(pageData.total / coursesPerPage))
            }          
        })
    }, [currentPage, coursesPerPage, pageData])   

    useEffect(() => {   
        if(courses && !_.isEmpty(courses)) {   
            dispatch(setTabEnabled({career_index: career.career_index, level: courseLevel.key}))   
        }
    }, [courses, courseLevel, career, tabsEnabled, dispatch])   
    
    return (
        <div className="courses-tab">           
            <div className="gradient-text card-holder-title">{career.name}</div>
            {
                _.isEmpty(courses) ?
                    <div className="no-courses">
                        No course found in selected locations
                    </div>
                :
                    <div className="card-holder" {...swipeHandlers}>
                        <div className="page-btn-holder left">
                            {
                                currentPage > 1 &&
                                    <div className="page-btn prev" onClick={() => setCurrentPage(currentPage - 1)}>                              
                                        <TiArrowBack />
                                    </div>
                            }
                        </div>                        
                        {
                            _.map(courses, course=> (
                                <CourseCard 
                                    key={`${career.id}${course.id}`} 
                                    course={course} 
                                    career={career} 
                                />
                            ))
                        }
                        <div className="page-btn-holder right">
                            {
                                currentPage < Math.ceil(pageData.total / coursesPerPage) &&
                                    <div className="page-btn next" onClick={() => setCurrentPage(currentPage + 1)}>
                                        <TiArrowForward />
                                    </div>
                            }
                        </div>    
                    </div>       
            }
            {
                pageData.total > coursesPerPage &&     
                    <div className="page-btns">   
                        <Pagination size="sm">
                            <Pagination.First 
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                            />
                            <Pagination.Prev 
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 1}
                            />                         
                            {

                                currentPage > 5 && 
                                    <>
                                        <Pagination.Item onClick={() => setCurrentPage(1)}>1</Pagination.Item>
                                        <Pagination.Ellipsis />   
                                    </>                        
                            }                                  
                            {
                                _.map(_.range(
                                        currentPage > 5 ? currentPage -2 : 1, 
                                        currentPage < (Math.ceil(pageData.total / coursesPerPage) - 4 ) ? (currentPage + 2) : (Math.ceil(pageData.total / coursesPerPage) + 1)
                                    ), pageNum => {
                                        return (
                                            <Pagination.Item 
                                                key={pageNum} 
                                                active={pageNum === currentPage}
                                                onClick={() => setCurrentPage(pageNum)}
                                            >
                                                {pageNum}
                                            </Pagination.Item>   
                                        ) 
                                    }
                                )
                            } 
                            {

                                currentPage < ((pageData.total / coursesPerPage) - 4 ) && 
                                    <>
                                        <Pagination.Ellipsis />   
                                        <Pagination.Item onClick={() => setCurrentPage(Math.ceil(pageData.total / coursesPerPage))}>
                                            {Math.ceil(pageData.total / coursesPerPage)}
                                        </Pagination.Item>                                
                                    </>                        
                            }                
                            <Pagination.Next 
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage === Math.ceil(pageData.total / coursesPerPage)}
                            />
                            <Pagination.Last  
                                onClick={() => setCurrentPage(Math.ceil(pageData.total / coursesPerPage))}
                                disabled={currentPage === Math.ceil(pageData.total / coursesPerPage)}
                            />
                        </Pagination>
                    </div>   
                }           
        </div>      
    )
}

export default CoursesTabContent