import { apiSlice } from "../../app/apiSlice";

export const eventAPI = apiSlice.injectEndpoints({    
    endpoints: builder => ({
        getEvents: builder.query({
            query: req => {
                const { start, end } = req
                return {
                    url: 'event', 
                    method: 'GET',
                    params: { start, end }    
                }
            }   
        })       
    })
})

export const  { useGetEventsQuery } = eventAPI