import { Container, Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import image from '../assets/img/Group 38196.png'
import { useSetStepsMutation } from '../features/user/userAPI' 
import { getCurrentUser, updateStepsCompleted } from '../features/auth/authSlice'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'


const CareerExperience = () => {      
   
    const user = useSelector(getCurrentUser)
    const [ setStepsCompleted ] = useSetStepsMutation(); 
    const dispatch = useDispatch()

    useEffect(() => {    
        if(user) {
            const bitSet = _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[3] 
            if(!bitSet || bitSet === '0') {
                setStepsCompleted({step_completed: 3})
                .then(result => dispatch(updateStepsCompleted({steps_completed: _.get(result, ['data', 'steps_completed'])})))
                .catch(error => console.log(error))
            }
        }
    }, [user, dispatch, setStepsCompleted]);



    return (       
        <Container fluid className="muvr-content career-exp">
            <Row className="top-row muvr-row">
                <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 1}} className="caption-col">
                    <div className="caption">
                        <div className="caption-title">Virtual Career Experience</div>                     
                        <div className="caption-text">
                            Gain insights into various professions through interactive simulations in virtual reality. Experience the day-to-day activities and challenges of different careers
                        </div>
                    </div>                    
                </Col>
                <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 1}}>       
                    <div className="img-holder">             
                        <img src={image} alt="virtual campus tours"/>   
                    </div>                
                </Col>
            </Row>
            <Row className="muvr-row">
                <Col className="centered-content">
                    <div className="banner-group">
                        <div className="banner-lg">DIVE INTO VR</div>
                        <div className="banner-inset">Explore the world of VR Careers</div>
                    </div>                  
                    <div className="lib-description">                       
                        Below is a library of VR experiences where you can have a 360 view of different college campuses
                    </div>
                </Col>
            </Row>   
            <Row className="vids-row">
                {
                    _.map([
                        { title: 'Bricklayer', url: 'https://www.youtube.com/embed/QyrP6lrRsx0' },
                        { title: 'Construction Electrician', url: 'https://www.youtube.com/embed/r9rA92HcAlI' },
                        { title: 'Roofers and Shinglers', url: 'https://www.youtube.com/embed/igXS_cybhcw' },
                        { title: 'Heavy Equipment Operator', url: 'https://www.youtube.com/embed/TidQE8kW2hA' },
                        { title: 'Welding Technology', url: 'https://www.youtube.com/embed/jdG8-A3zL4I' },
                        { title: 'Personal Support Worker', url: 'https://www.youtube.com/embed/XunQhlnqIXo' },                        
                        { title: 'Social Workers', url: 'https://www.youtube.com/embed/4mqylDsZmY0' },
                        { title: 'Registered Nurse', url: 'https://www.youtube.com/embed/gniLblXTxpM' },
                        { title: 'Cyber Security', url: 'https://www.youtube.com/embed/N971nTERz1k' },
                        { title: 'School Teachers', url: 'https://www.youtube.com/embed/XjM-Ls2sW3Y' },
                        { title: 'Psychologists', url: 'https://www.youtube.com/embed/vpB1VQ8DaiI' },
                        { title: 'Fish and Seafood Processing', url: 'https://www.youtube.com/embed/RAPiRYrxIa8' },
                        { title: 'Automotive Service Technicians', url: 'https://www.youtube.com/embed/yML1K2DvLhE' },
                        { title: 'Civil Engineers', url: 'https://www.youtube.com/embed/HTiGwdXnlhY' },
                        { title: 'Carpenters', url: 'https://www.youtube.com/embed/I1qcea8133w' },
                        { title: 'Transport Truck Drivers', url: 'https://www.youtube.com/embed/t_1WFZhivZQ' },
                        { title: 'Computer programmers and interactive media developers', url: 'https://www.youtube.com/embed/cPBmfDwiFtk' },
                        { title: 'Information systems testing analysts', url: 'https://www.youtube.com/embed/cggu9RlvFaU' },
                        { title: 'Youth Entrepreneur: small business owners', url: 'https://www.youtube.com/embed/MPuEJLFvK48' },
                        { title: 'Entrepreneur: tech start-ups', url: 'https://www.youtube.com/embed/sqQ1CrbC7Qc' },
                        { title: 'Technical occupations in health', url: 'https://www.youtube.com/embed/wsxsaC8610g' },
                        { title: 'Professional occupations in business and finance', url: 'https://www.youtube.com/embed/eBy2yPwAB1A' }                   
                    ], vid => (
                        <Col key={vid.title}>
                            <div className="help-vid">
                                <iframe 
                                    width="420" 
                                    height="280" 
                                    src={vid.url} 
                                    title={vid.title} 
                                    frameBorder="0" 
                                    allow="fullscreen"
                                >                                
                                </iframe>
                            </div>                       
                            <div className="vid-text">
                                {vid.title}
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </Container>  
    )
}

export default CareerExperience