import { useSelector } from 'react-redux'
import { getTranslation } from '../app/appSlice'
import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import FormDecorator from '../components/FormDecorator'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import Error from '../components/Error'
import { useResetPasswordMutation } from '../features/auth/authAPI'
import { useNavigate } from 'react-router-dom'


const ForgotPassword = () => {     
    const emailLabel = useSelector(getTranslation('register.email.label'))    
    const navigate = useNavigate()
    const [customError, setCustomError] = useState(null)
    const [formEmail, updateFormEmail] = useState('');     
    const [ resetPassword, data ] = useResetPasswordMutation(); 
    const instructions = "Enter your email to recieve an email to reset your password:"
    
    const submitForm = e => {    
    	e.preventDefault()  
        resetPassword(formEmail)
    }

    useEffect(() => {        
        if (_.get(data, ['isSuccess'])) {           
            navigate('/reset-code')
        }
        else if (_.get(data, ['isError'])) {
            if(_.get(data, ['error', 'status']) === 400) {
                setCustomError('Email does not exists')
            }
        }
    }, [data, navigate])


    return (
        <Container className="muvr-content">
            <Row className="top-row">
                <Col lg={{ span: 10, offset: 1 }} className="centered-content">
                    <div className="form-holder backdrop decorator">                      
                        <Form className="muvr-form" onSubmit={submitForm}>
                            <div className="muvr-form-body">
	                            <div className = "form-title">   
	                                Forgotten Password
	                            </div>   
	                            {instructions}
	                            <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>{emailLabel + ' :'}</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        size="lg"
                                        value={formEmail}
                                        onChange={e => updateFormEmail(_.get(e, ['target', 'value']))}
                                    />                            
                                </Form.Group>
                                <div className="form-error">
                                    {customError && <Error>{customError}</Error>}
                                </div>
                            </div>                            
                            <div className="form-actions">
                                <Button variant="secondary" type="submit" size="lg">
                                    Send Email
                                </Button>
                            </div>
                        </Form>      
                        <FormDecorator />
                    </div>                        
                </Col>                
            </Row>
        </Container>
    )
}

export default ForgotPassword