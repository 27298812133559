import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import { useGetRankedUserCoursesQuery, useGetCoursesListQuery } from '../features/simulator/simulatorAPI'
import Select from 'react-select'
import { setFormValue, getForm, copyCourse, getCopiedCourse } from '../features/simulator/simulatorSlice'
import { useSetStepsMutation } from '../features/user/userAPI' 
import { getCurrentUser, updateStepsCompleted } from '../features/auth/authSlice'
import { useEffect } from 'react' 
import { BiTrash } from 'react-icons/bi'
import { useUpdateUserCoursesMutation } from '../features/user/userAPI'
import { Link } from 'react-router-dom'
import copyImg from '../assets/img/copy.png'
import pasteImg from '../assets/img/paste.png'
import DAREHandbook from '../assets/pdf/DARE_handbook_2023.pdf'
import DAREInfo from '../assets/pdf/DAREInformationLeaflet2023.pdf'
import DAREPresentation from '../assets/pdf/DARESchoolPresentation2023.pdf'
import HEARHandbook from '../assets/pdf/HEAR_handbook_2023.pdf'
import HEARPresentation from '../assets/pdf/HEARSchoolPresentation2023.pdf'
import HEARSocial from '../assets/pdf/HEAR_Social_doc .pdf'
import {GrDocumentPdf} from 'react-icons/gr'


const selectStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {           
        return {
            ...styles, 
            'backgroundColor': 'white;', 
            'color': 'black',         
            ':hover' : { 
                backgroundColor: '#7157F8'
            },                
        };
    }        
};

const SmallLegend = () => (
    <div className="small-legend">
        <div className="suggested-text">
            This is a suggested format  based on the course points and the grades you have logged previously. Ranked from most likely to be offered to least likely
        </div>
        <div className="small-legend-text">
            Aspirational Courses  1-3
            <div>
                (over and above where you are tracking with points)
            </div>
        </div>
        <div className="small-legend-text">
            Realistic Courses 4-7
            <div>
                (exactly where you are tracking)    
            </div>
        </div>
        <div className="small-legend-text">
            Safety Net Courses 8-10
             <div>
                (your lowest points score over the last two years)   
            </div>
        </div>
    </div>
    )

const pdfs = [
        {
            filename: 'DARE_handbook_2023.pdf',
            file: DAREHandbook,
            label: 'DARE Handbook 2023',
            color: "#AFDBF3"
        },
        {
            filename: 'DAREInformationLeaflet2023.pdf',
            file: DAREInfo,
            label: 'DARE Information Leaflet 2023',
            color: "#CCCBF0"
        },
        {
            filename: 'DARESchoolPresentation2023.pdf',
            file: DAREPresentation,
            label: 'DARE School Presentation 2023',
            color: "#FAE4CD"
        },
        {
            filename: 'HEAR_handbook_2023.pdf',
            file: HEARHandbook,
            label: 'HEAR handbook 2023',
            color: "#C1F0BC"
        },
        {
            filename: 'HEARSchoolPresentation2023.pdf',
            file: HEARPresentation,
            label: 'HEAR School Presentation 2023',
            color: "#FFD3DB"
        },
        {
            filename: 'HEAR_Social_doc .pdf',
            file: HEARSocial,
            label: 'HEAR Social Doc',
            color:  "#B5EFDE"
        }
    ]

const CAOSimulator = () => {   
    const userCourses = _.get(useGetRankedUserCoursesQuery(), ['data', 'courses'])   
    const dispatch = useDispatch()
    const level8Form = useSelector(state => getForm(state, 'level8'))
    const level67Form = useSelector(state => getForm(state, 'level67'))
    const courses = _.get(useGetCoursesListQuery(), ['data', 'courses'])   
    const coursesLoading = _.get(useGetCoursesListQuery(), ['isLoading'])   
    const user = useSelector(getCurrentUser)
    const [ setStepsCompleted ] = useSetStepsMutation(); 
    const copiedCourse = useSelector(getCopiedCourse)
    const [ updateUserCourses ] = useUpdateUserCoursesMutation();  


    useEffect(() => {    
        if(user) {
            const bitSet = _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[5] 
            if(!bitSet || bitSet === '0') {
                setStepsCompleted({step_completed: 5})
                .then(result => dispatch(updateStepsCompleted({steps_completed: _.get(result, ['data', 'steps_completed'])})))
                .catch(error => console.log(error))
            }
        }
    }, [user, dispatch, setStepsCompleted]);

    return (
        <Container className="muvr-content simulator">
            <Row className="top-row muvr-row">
                <Col xs={10}>
                    <div className="title">
                        <div className="main-title">CAO Simulator</div>
                    </div>                   
                </Col>                
            </Row>
            <Row className="muvr-row">
                <Col xs={12} className="centered-content">
                    <div className="sub-title">
                        This a MOCK CAO form. Please go to <a href="http://www.cao.ie" target="_blank" rel="noreferrer">www.cao.ie</a>  
                    </div> 
                    <div className="sub-description">
                        Fill out this MOCK CAO in genuine order of preference. Fill it out exactly how you will fill out your actual CAO form
                    </div>
                </Col>           
            </Row>
            <Row className="muvr-row">
                <Col xs={12} className="centered-content">
                    <div className="form-description">
                        Familiarise yourself with the CAO application process through our interactive Mock CAO form. Gain hands-on experience before submitting the actual application
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="mock-form-title gradient-text">
                        Level 8 Choices
                    </div>
                </Col>                   
            </Row>
            <Row>
                <Col xs={12} md={7} lg={6} className="centered-content">
                    <SmallLegend />
                </Col>                                
            </Row>
            <Row>
                <Col xs={7} lg={6}>                   
                    <Form className="mock-form">
                        {
                            _.map(level8Form, (formRow, index) => (
                                 <Row key={index}>
                                    <div className="mock-form-item">
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column xs={2}>{index + 1}</Form.Label>
                                            <Col xs={8}>
                                                <Select            
                                                    className="sim-select"
                                                    classNamePrefix="muvr-select"
                                                    value={ formRow ? {
                                                            label: formRow.course_code,
                                                            value: formRow                                       
                                                        } 
                                                        : false
                                                    }
                                                    isSearchable={true}
                                                    onChange={ selected => dispatch(setFormValue({
                                                        value: selected.value, 
                                                        form: 'level8', 
                                                        index
                                                    })) }
                                                    options={_.map(_.filter(courses, c => c.level === 8 && !_.find(level8Form, fr => fr && fr.id === c.id)), c => {return {label: `${c.course_code} - ${c.title}` , value: c}})}  
                                                    placeholder="CAO Code"
                                                    styles={selectStyles}
                                                    isDisabled={coursesLoading}
                                                /> 
                                            </Col>
                                            <Col xs={1}>   
                                                {
                                                    _.get(copiedCourse, ['level']) === 8 &&
                                                        <div 
                                                            className={classNames("paste-icon", {disabled: !copiedCourse || copiedCourse.level !== 8})}
                                                            onClick={() => dispatch(setFormValue({
                                                                    value: copiedCourse, 
                                                                    form: 'level8', 
                                                                    index,
                                                                    paste: true
                                                                }))}
                                                            >
                                                            <img src={pasteImg} alt="paste"/>
                                                        </div>
                                                }
                                                
                                            </Col>                     
                                            <div className="btn-holder">   
                                                <div className="anchor-btn" onClick={() => dispatch(setFormValue({
                                                        value: undefined, 
                                                        form: 'level8', 
                                                        index
                                                    }))}
                                                >
                                                    Remove Code
                                                </div> 
                                            </div>                                                            
                                        </Form.Group>                                        
                                    </div>
                                </Row>
                            ))
                        }                       
                    </Form>
                </Col>                
                <Col xs={5} lg={6}>    
                    {
                        !_.isEmpty(_.filter(userCourses, uc => uc.level === 8)) &&
                            <Form className="suggested-form">
                                {
                                    _.take(_.filter(userCourses, uc => uc.level === 8), 10).map((v, k) => (
                                        <Row key={k}>
                                            <div className="suggested-form-item">
                                                <Form.Group as={Row} className="mb-3">
                                                    <Col xs={6} className="no-padding">
                                                        <Form.Control onChange={() => {}} value={v.course_code}/>       
                                                    </Col>    
                                                    <Col xs={1}>                                               
                                                        <div 
                                                            className={classNames(
                                                                "copy-icon", 
                                                                {"copied": _.get(copiedCourse, ['id']) === v.id},
                                                                {"disabled": _.find(level8Form, c => _.get(c, ['id']) === v.id)}
                                                            )} 
                                                            onClick={() => dispatch(copyCourse(v))}
                                                        >
                                                            <img src={copyImg}  alt="copy"/>
                                                        </div>                                                
                                                    </Col>    
                                                    <Col xs={1}>                                               
                                                        <div 
                                                            className={"remove-icon"} 
                                                            onClick={() => updateUserCourses({
                                                                action: 'remove',
                                                                course_id: v.id 
                                                            })}
                                                        >
                                                            <BiTrash />
                                                        </div>                                                
                                                    </Col>                                                                                              
                                                </Form.Group>                  
                                            </div>
                                        </Row>
                                    ))
                                }
                            </Form>
                        }
                </Col>            
            </Row>            
            <Row className="muvr-row">
                <Col xs={12}>
                    <div className="mock-form-title gradient-text">
                        Level 6 + 7 Choices
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={7} lg={6} className="centered-content">
                    <SmallLegend />
                </Col>                      
            </Row>
            <Row>
                <Col xs={7} lg={6}>
                    <Form className="mock-form">
                        {
                            _.map(level67Form, (formRow, index) => (
                                 <Row key={index}>
                                    <div className="mock-form-item">
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column xs={2}>{index + 1}</Form.Label>
                                            <Col xs={8}>
                                                <Select            
                                                    className="sim-select"
                                                    classNamePrefix="muvr-select"
                                                    value={ formRow ? {
                                                            label: formRow.course_code,
                                                            value: formRow                                       
                                                        } 
                                                        : false
                                                    }
                                                    isSearchable={true}
                                                    onChange={ selected => dispatch(setFormValue({
                                                            value: selected.value, 
                                                            form: 'level67', 
                                                            index
                                                        })) 
                                                    }
                                                    options={_.map(_.filter(courses, c => (c.level === 6 || c.level === 7) && !_.find(level67Form, fr => fr && fr.id === c.id)), c => {return {label: `${c.course_code} - ${c.title}` , value: c}})}  
                                                    placeholder="CAO Code"
                                                    styles={selectStyles}
                                                    isDisabled={coursesLoading}
                                                /> 
                                            </Col>
                                            <Col xs={1}>   
                                                {
                                                    (_.get(copiedCourse, ['level']) === 7 || _.get(copiedCourse, ['level']) === 6) &&
                                                        <div 
                                                            className={classNames("paste-icon", {disabled: !copiedCourse || copiedCourse.level === 8})}
                                                            onClick={() => 
                                                                dispatch(setFormValue({
                                                                    value: copiedCourse, 
                                                                    form: 'level67', 
                                                                    index,
                                                                    paste: true
                                                                }))
                                                            }
                                                        >
                                                            <img src={pasteImg}  alt="paste"/>
                                                        </div>
                                                }
                                            </Col>         
                                            <div className="btn-holder">   
                                                <div 
                                                    className="anchor-btn" 
                                                    onClick={() => dispatch(setFormValue({
                                                            value: undefined, 
                                                            form: 'level67', 
                                                            index
                                                        }))
                                                    }
                                                >
                                                    Remove Code
                                                </div> 
                                            </div>                                                            
                                        </Form.Group>                                        
                                    </div>
                                </Row>
                            ))
                        }                       
                    </Form>
                </Col>             
                <Col xs={5} lg={6}>       
                    {
                        !_.isEmpty(_.filter(userCourses, uc => uc.level === 7 || uc.level === 6)) &&             
                        <Form className="suggested-form">
                            {
                                _.filter(userCourses, uc => uc.level === 7 || uc.level === 6).map((v, k) => (
                                    <Row key={k} >
                                        <div className="suggested-form-item">
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column xs={2}>{k + 1}</Form.Label>
                                                <Col xs={6} className="no-padding">
                                                    <Form.Control onChange={() => {}} value={v.course_code}/>       
                                                </Col>        
                                                <Col xs={1}>   
                                                    <div 
                                                        className={classNames(
                                                            "copy-icon", 
                                                            {"copied": _.get(copiedCourse, ['id']) === v.id},
                                                            {"disabled": _.find(level67Form, c => _.get(c, ['id']) === v.id)}
                                                        )} 
                                                        onClick={() => dispatch(copyCourse(v))}
                                                    >
                                                        <img src={copyImg}  alt="copy"/>
                                                    </div>
                                                </Col>  
                                                <Col xs={1}>                                               
                                                    <div 
                                                        className={"remove-icon"} 
                                                        onClick={() => updateUserCourses({
                                                            action: 'remove',
                                                            course_id: v.id 
                                                        })}
                                                    >
                                                        <BiTrash />
                                                    </div>                                                
                                                </Col>                                                                                                             
                                            </Form.Group>                  
                                        </div>
                                    </Row>
                                ))
                            }
                        </Form>
                    }
                </Col>       
            </Row>
            <Row className="banner-row">
                <Col className="centered-content">
                    <div className="banner-group">
                        <div className="banner-lg">WHAT IS THE CAO?</div>
                        <div className="banner-inset">WHAT IS THE CAO?</div>
                    </div>            
                </Col>
            </Row>   
            <Row className="vids-row">                
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            src="https://player.vimeo.com/video/80052547?h=2a204a8cc7" 
                            width="420" 
                            height="280" 
                            framezorder="0" 
                            allow="fullscreen" 
                            allowFullScreen
                            title="Applying to CAO"
                        />
                    </div>                       
                    <div className="vid-text">
                        Applying to CAO
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            src="https://player.vimeo.com/video/151786032?h=00f4876e01" 
                            width="420" 
                            height="280" 
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen 
                            title="Obtaining your CAO number"
                        />
                    </div>
                    <div className="vid-text">
                        Obtaining your CAO number
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            src="https://player.vimeo.com/video/152400281?h=4457875ffd" 
                            width="420" 
                            height="280"
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                            title="Editing your CAO application"
                        />                     
                    </div>
                    <div className="vid-text">
                        Editing your CAO application   
                    </div>   
                </Col>            
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            src="https://player.vimeo.com/video/86519317?h=299b542830" 
                            width="420" 
                            height="280"
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                            title="CAO Change of mind"
                        />                            
                    </div>
                    <div className="vid-text">
                        CAO Change of mind
                    </div>  
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            src="https://player.vimeo.com/video/102134759?h=2ba976b96a" 
                            width="420" 
                            height="280"
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                            title="The Offer and Acceptance process"
                        />                        
                    </div>
                    <div className="vid-text">
                        The Offer and Acceptance process                   
                    </div>
                </Col>
            </Row>
            <Row className="vids-row">
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            src="https://player.vimeo.com/video/780720527?h=c0deaf5533" 
                            width="420" 
                            height="280" 
                            frameBorder="0" 
                            allow="fullscreen" 
                            allowFullScreen 
                        />
                    </div>
                    <div className="vid-text">
                        How to apply to HERE 2023
                    </div>  
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            src="https://player.vimeo.com/video/780723133?h=d382d087e7" 
                            width="420" 
                            height="280" 
                            frameBorder="0" 
                            allow="fullscreen" 
                            allowFullScreen 
                        />
                    </div>
                    <div className="vid-text">
                        How to apply to DARE 2023                 
                    </div>
                </Col>
            </Row>
            <Row className="muvr-row">
                <Col xs={12} sm={{span: 10, offset: 1}} md={{span: 8, offset: 2}} className="centered-content">
                    <div className="help-pdf-title gradient-text">Helpful Pdfs</div>
                    <div className="help-pdf-sub-title">Here you will find helpful pdfs to download and view</div>
                    <Container className="help-pdfs">     
                        <Row>                        
                            {
                                pdfs.map((pdf, index) => (
                                    <Col key={index} className="centered-content">
                                        <Card className="pdf-card" key={pdf.filename} style={{backgroundColor: pdf.color}}>
                                            <Card.Header>
                                                {pdf.label}    
                                            </Card.Header>
                                            <Card.Body>
                                                <GrDocumentPdf />
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href={pdf.file} download>View</a> 
                                            </Card.Footer>                                
                                        </Card>
                                    </Col>
                                ))   
                            }   
                        </Row>                     
                    </Container>
                </Col>
            </Row>
            <Row className="muvr-row">
                <Col className="centered-content">
                    <div className="muvr-step-link">
                        <div className="step-link">
                            <div className="gradient-text">
                                Continue to Academic Support
                            </div>
                            <Button as={Link} to="/support" size="lg" variant="outline-primary">
                                Academic Support
                            </Button>
                        </div>        
                    </div>                                    
                </Col>
            </Row>    
        </Container>
    )
}

export default CAOSimulator