import { Card, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import { useUpdateUserCareersMutation } from '../../user/userAPI'

const CareerCard = props => {
    const [ updateUserCareers ] = useUpdateUserCareersMutation();  
    const handleCareerRemoval = career => {      
        updateUserCareers({
            action: 'remove',
            career_id: career.id 
        })        
    }

    const importAll = r => {
        let images = {};
        _.map(r.keys(), (item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }    
    const images = importAll(require.context('../../../assets/img/careerImages', false, /\.(png|jpe?g)$/));

    return (       
        <Card key={props.career.id} className="career-card">
            <Card.Header>
                <Card.Title>
                    {props.career.name}    
                </Card.Title> 
                <Card.Img 
                    variant="top" 
                    src={_.get(images, [_.get(props.career, ['image_filename'])])}
                />    
            </Card.Header>
            <Card.Body>     
                <Card.Subtitle className="career-desc">
                    {props.career.description}
                </Card.Subtitle>     
                <Row>
                    <Col>
                        <Card.Subtitle>
                            Interests
                        </Card.Subtitle>   
                        <Card.Text>
                            {_.map(props.career.holland_codes, (hc, index) => `${index === 0 ? '' : ', '}${hc.name}`)}
                        </Card.Text>    
                    </Col>
                    <Col>
                        <Card.Subtitle>
                            Potential Salary
                        </Card.Subtitle>   
                        <Card.Text>
                            {props.career.salary === -1 ? "Unlimited" : ('€' + props.career.salary)}
                        </Card.Text>   
                    </Col>
                
                    <Col>
                        <Card.Subtitle>
                            5 Yr Future Outlook
                        </Card.Subtitle>   
                        <Card.Text>
                            {`${Math.abs(_.get(props.career, ['future_outlook']))}% ${_.get(props.career, ['future_outlook']) > 0 ? 'growth' : 'decline'}`}
                        </Card.Text>   
                    </Col>
                    <Col>                        
                        <Card.Subtitle>
                            AI Risk (W.E.F)
                        </Card.Subtitle>   
                        <Card.Text>
                            {`${props.career.automation_risk * 100}%`}
                        </Card.Text>     
                    </Col>
                </Row>             
            </Card.Body>
           <Card.Footer>  
                <Row>
                    <Col>
                        <div className="btn-holder">                             
                            <Button 
                                variant="secondary" 
                                size="sm"
                                onClick={() => handleCareerRemoval(props.career)}                                   
                            >
                                Remove
                            </Button>
                        </div>
                    </Col>
                    {
                        props.career.taster_url &&                    
                            <Col>
                                <div className="btn-holder">                             
                                    <Button 
                                        variant="secondary" 
                                        size="sm"
                                        onClick={() => window.open(props.career.taster_url, "_blank")}                                   
                                    >
                                        Taster Course
                                    </Button>
                                </div>    
                            </Col>
                    }
                </Row>   
            </Card.Footer>
        </Card>      
    )
}

export default CareerCard