import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import FormDecorator from '../components/FormDecorator'

const Terms = () => {   
    return (
        <Container className="muvr-content">
            <Row className="top-row">
                <Col lg={{ span: 10, offset: 1 }} className="centered-content">
                    <div className="form-holder backdrop decorator">    
                        <Form className="muvr-form">
                            <div className="muvr-form-body">
                                <div className = "form-title">   
                                    Terms and Conditions
                                </div>   
                                <div className="form-text" style={{height: '550px', overflow: 'auto'}}>  
                                    Welcome to MyCareerVerse.com This policy explains how MyCareerVerse collects, uses and shares information about you when you visit or use the MyCareerVerse and CAOAngel platform through our mobile app or via our website (“Platform”). When we say “MyCareerVerse  “us”, “we”, or “our/ours”, we generally mean MyCareerVerse  Limited.
                                    <br/>
                                    <br/>
                                    Below is a list of sections in this notice:
                                    <br/>
                                    Personal Information We Collect
                                    <br/>
                                    Use of Your Personal Information
                                    <br/>
                                    Disclosure of Your Personal Information
                                    <br/>
                                    Cookies and tracking technologies
                                    <br/>
                                    Data Retention
                                    <br/>
                                    International Transfer
                                    <br/>
                                    Our Policy Toward Children
                                    <br/>
                                    Your Rights and Contacting Us
                                    <br/>
                                    Other Websites
                                    <br/>
                                    Who is responsible for my information?
                                    <br/>
                                    Policy Changes
                                    <br/>
                                    We have also included various links throughout this document so you can find out more detailed information in particular respects by clicking on the links. This helps us keep this notice shorter and easier to read.
                                    <br/>
                                    <br/>
                                    1 Personal Information We Collect
                                    <br/>
                                    MyCareerVerse and CAOAngelcollect various kinds of personal information depending on the circumstances, such as: Information you provide to us.
                                    <br/>
                                    Your email address, date of birth, and similar contact info (like when you register for an account, or provide contact details though our website);
                                    <br/>
                                    Your username, password, and other details that help us secure and provide access to the Platform (like when you login to our Platform or websites);
                                    <br/>
                                    Information you share with us by building your online profile on MyCareerVerse and CAOAngel and engaging with our online skills and interest assessments;
                                    <br/>
                                    Your support-related info, including the nature of your concern and your account or order details (like when you request help from us);
                                    <br/>
                                    Information you share with us in connection with contests or promotions (like to submit an entry, prove your eligibility, or claim a prize if you win);
                                    <br/>
                                    Your marketing and communication preferences; 
                                    <br/>
                                    Information we collect by automated means. When you visit our website, our server logs automatically record information such as IP address, operating system, browser type, domain of visitor, time and duration of visit and pages requested. These statistics are collected and used only on an aggregate basis.
                                    <br/>
                                    Information we collect from third parties. We will receive personal data about you from various third parties as set out below:
                                    <br/>
                                    analytics providers [such as Google based outside the EU]; and Facebook Fan Pages.
                                    <br/>
                                    We will inform you at the time of collection whether the information we request is mandatory.
                                    <br/>
                                    2 Use of Your Personal Information
                                    <br/>
                                    We use the personal information we collect (including by combining information collected in different ways) for a variety of purposes depending on how you interact with us/our Platform. This includes:
                                    <br/>
                                    <br/>
                                    Provision of the Platform. In order to provide you with our Platform and perform our contract with you, we may use your personal information in a number of ways. This includes using your personal information to:
                                    <br/>
                                    <br/>
                                    enable you as a potential candidate to take part in our skills assessments available on the Platform, 
                                    <br/>
                                    generally to provide the Platform to you, respond to inquiries, and fulfil requests (like helping you create an account, and resolve support issues);
                                    <br/>
                                    provide requested customer service, respond to your service, and or other inquiries about our Platform and contact you regarding your service or account; and confirm the identity of our customers and users that call for customer service (for instance, by verifying account number, address or other unique identifiers such as your student number).
                                    <br/>
                                    Business purposes. We also use your personal information where it is necessary as part of our day-to-to-day commercial activities and is in our or others’ legitimate interests as a business. This includes using your personal information to:
                                    <br/>
                                    keep our service secure;
                                    <br/>
                                    learn about our users’ interests and preferences and undertake internal aggregate market and other analysis so we can improve our Platform;
                                    <br/>
                                    prepare, tailor and send marketing materials that directly relate to your use of our products or our service or that we think interest you;
                                    <br/>
                                    respond to your requests for more information about our Platform;
                                    <br/>
                                    manage our customer and partner relationships;
                                    <br/>
                                    maintain business records for reasonable periods, and generally manage and administer our business; and
                                    <br/>
                                    provide information to our professional advisers where necessary, such as our accountants and lawyers.
                                    <br/>
                                    Marketing. MyCareerVerse and CAOAngel may also use your marketing preferences and contact information and information about your past purchases or service options to send you information about other products or services that we think would be of interest to you, based upon the information we hold about you. We do this based upon our legitimate interest in sending you information about products or services we think will interest you and, where required by law, with your consent.
                                    <br/>
                                    MyCareerVerse and CAOAngel recruitment. If you have applied for a job with us, we use information about you to consider whether you are suitable for a position with us and to verify the accuracy of the information you have provided. We do this based upon our legitimate interest in employing and engaging suitable and well qualified people.
                                    <br/>
                                    Fraud, compliance and legal reasons. We may also need to use your personal information in order to prevent fraud, defend or exercise our legal rights or in order to comply with legal obligations, i.e. to meet legal, regulatory, security, audit and processing requirements or otherwise as permitted or required by law. We do this based upon those legal requirements or our legitimate interest in that regard.
                                    <br/>
                                    Digital Age of Consent
                                    <br/>
                                    MyCareerVerse wishes to assure all users that measures have been put in place to ensure that we comply with the new GDPR stipulation that all children under 16 are required to have parental consent before providing us with their personal data. Any personal data provided to us by individuals, be they under 16 or over 16, will be treated with the upmost care and security.
                                    <br/>
                                    <br/>
                                    3 Disclosure of Your Personal Information
                                    <br/>
                                    We may disclose your personal information to:
                                    <br/>
                                    Affiliates. Your personal information may be disclosed to certain affiliates for internal audit, management, billing or administrative purposes. [Some of MyCareerVerse’s affiliates are located outside of the European Economic Area, so MyCareerVerse and CAOAngel may transfer and process your personal information to countries other than your country of residence.] For more information, please see the "International Transfer" section below.
                                    <br/>
                                    Service Providers and Professional Advisers. MyCareerVerse and CAOAngel may transfer personal information to outside agents or service providers (including affiliates of MyCareerVerse and CAOAngel acting in this capacity) that perform services on our behalf, for example billing, mailing, collection or information technology hosting or processing services, as well as our professional advisers (who are often independent ‘data controllers’) such as lawyers and accountants. [Some of these may be located outside of the European Economic Area and your personal information may be collected, used, disclosed, stored and processed outside of the European Economic Area for the purposes described in this notice.]
                                    <br/>
                                    Mergers and Business Transactions. Your personal information may be used by MyCareerVerse and disclosed to parties connected with the contemplated or actual financing, insuring, sale, assignment or other disposal of all or part of our business or assets (including, for example, your service account with us), for purposes related to the evaluation and performance of these transactions or to give effect to them, including:
                                    <br/>
                                    permitting such parties to determine whether to proceed or continue with the transaction;
                                    <br/>
                                    fulfilling reporting, inspection or audit requirements or obligations to such parties; and
                                    <br/>
                                    the use and disclosure of your personal information by such parties for substantially the same purposes as described in this statement.
                                    <br/>
                                    4 Cookies and tracking technologies
                                    <br/>
                                    Our site makes use of cookies and similar technologies in accordance with our Cookies Notice.
                                    <br/>
                                    5 Data Retention
                                    <br/>
                                    We retain your information for the period necessary to fulfil the purposes outlined in this notice or such longer retention period as may be required or permitted by applicable law. For instance, we may keep your information to comply with our legal obligations (e.g. tax laws), to resolve disputes, to enforce our contracts or as otherwise permitted by law.
                                    <br/>
                                    The precise periods for which we keep your personal information vary depending on the nature of the information and why we need it. Factors we consider in determining these periods include the minimum required retention period prescribed by law or recommended as best practice, the period during which a claim can be made with respect to a contract or other matter, whether the personal information has been aggregated or pseudonymized, and other relevant criteria. For example, the retention period applicable for your personal data needed to provide the Platform service to you is limited to your account life (including any period where your account is dormant). When your account is deleted, we will delete the relevant information for that purpose (providing the service to you) but will keep some personal information (user name for instance) for other defined purposes such as security and fraud detection.
                                    <br/>
                                    <br/>
                                    Where personal information is provided to request direct marketing and similar materials from MyCareerVerse, we keep that personal information until you ask us not to send any further such materials. We will keep a record of any request not to receive these materials to ensure we do not send you marketing emails in the future.
                                    <br/>
                                    6 International Transfer
                                    <br/>
                                    Some of our external third parties are based outside the EEA so their processing of your personal data will involve a transfer of data outside the EEA.
                                    <br/>
                                    Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
                                    <br/>
                                    We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.
                                    <br/>
                                    Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries.
                                    <br/>
                                    Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.
                                    <br/>
                                    7 Our Policy Toward Children
                                    <br/>
                                    MyCareerVerse require users under the age of 16 to obtain consent from parents or guardians before creating an account.
                                    <br/>
                                    8 Your Rights and Contacting Us
                                    <br/>
                                    Where MyCareerVerse  holds your personal information, it is accessible at [40 Drumcondra road lower. Dublin 9, Ireland].
                                    <br/>
                                    You can also unsubscribe from marketing communications at any time by contacting us or using the unsubscribe link in the message.
                                    <br/>
                                    Your rights include:
                                    <br/>
                                    right to access and to obtain information regarding the processing of your personal information we control;
                                    <br/>
                                    right to rectify your personal information;
                                    <br/>
                                    right to erase your personal information (right to be forgotten);
                                    <br/>
                                    right to restrict processing of your personal information;
                                    <br/>
                                    right to lodge a complaint with a supervisory authority;
                                    <br/>
                                    right to object to processing, including for direct marketing purposes; and
                                    <br/>
                                    right to data portability,
                                    <br/>
                                    right to withdraw any consent you give us or unsubscribe from marketing messages,
                                    <br/>
                                    You should note that the withdrawal of your consent (including for marketing purposes) does not affect the lawfulness of processing based on your consent before its withdrawal. If you withdraw your consent in some cases, it may impact upon our ability to provide certain services to you.
                                    <br/>
                                    If you wish to contact us or exercise your rights, you may write to the address set out in section 10, attention Privacy Officer, or contact us at angelacurran@careerverse.ie
                                    <br/>
                                    The Irish Data Protection Commission is the lead supervisory authority of MyCareerVerse under EU data protection law. Its contact details are:
                                    <br/>
                                    Office of the Data Protection Commissioner
                                    <br/>Canal House, Station Road
                                    <br/>
                                    Portarlington, Co. Laois, R32 AP23, Ireland
                                    <br/>
                                    Phone: +353 (0761) 104 800
                                    <br/>
                                    LoCall: 1890 25 22 31
                                    <br/>
                                    Fax: +353 57 868 4757
                                    <br/>
                                    Email: angelacurran@careerverse.ie
                                    <br/>
                                    <br/>
                                    9 Other Websites
                                    <br/>
                                    Our website may contain links to other websites. These websites have different privacy notices and practices to MyCareerVerse's. MyCareerVersel has no responsibility for such third party websites. You should review the privacy notice of any third party website you visit.
                                    <br/>
                                    10 Who is responsible for my information?
                                    <br/>
                                    MyCareerVerse is responsible for your personal information. Our data protection officer is Angela Curran and is contactable at angelacurran@careerverse.ie for any queries.
                                    <br/>
                                    MyCareerVerse Limited’s registered company number is 722685 and its registered office is at ABC Business Centre 40 Lower Drumcondra Road Dublin 9 Ireland.
                                    <br/>
                                    <br/>
                                    <br/>
                                    11 Policy Changes
                                    <br/>
                                    We’ll update this notice from time to time to reflect changes in technology, law, our business operations or any other reason we determine is necessary or appropriate. When we make changes, we’ll update the “effective date” text at the top of the notice and post it on our sites. If we make material changes to it or the ways we process personal information, we’ll notify you (by, for example, prominently posting a notice of the changes on our sites before they take effect or directly sending you a notification).
                                    <br/>
                                    We encourage you to check back periodically to review this notice for any changes since your last visit. This will help ensure you better understand your relationship with us, including the ways we process your personal information
                                    <br/>
                                    Accessing Your Information
                                    <br/>
                                    You are entitled to see the information we hold about you. On written request to info@careerverse.ie, we supply copies of your personal data which you may have supplied to us using our website.
                                    <br/>
                                    MyCareerVerse’s mission is to provide users with a resource that allows them to research and decide on future careers, employment and educational options. We do not process personal data for any other reason than to provide this service to our users.
                                    <br/>
                                    Should any user have any questions about their personal data, or wish their personal data to be amended, accessed or erased, please do not hesitate to contact us at info@careerverse.ie
                                    <br/>
                                    <br/>
                                    Use the CAO Calculator to start your journey…..    
                                </div>
                                <div className="form-text">  
                                </div>
                            </div>
                            <div className="form-actions"> 
                                <Button variant="secondary" type="submit" size="lg">
                                    Agree
                                </Button>
                            </div>    
                        </Form>
                        <FormDecorator />  
                    </div> 
                </Col>                
            </Row>
        </Container>
    )
}

export default Terms