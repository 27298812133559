import { Container, Row, Col, Tabs, Tab, Card, Badge, Button } from 'react-bootstrap'
import { useGetEventsQuery } from '../features/notifications/eventAPI'
import { getActiveTab, setActiveTab, getActiveStartDate, setActiveStartDate } from '../features/notifications/notificationSlice'
import _ from 'lodash'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import Calendar from 'react-calendar';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'
import classNames from 'classnames'
import { useSetStepsMutation } from '../features/user/userAPI' 
import { getCurrentUser, updateStepsCompleted } from '../features/auth/authSlice'
import { useEffect } from 'react'
import { openModal } from '../app/appSlice'


const VidsLeft = () => _.map([
        { title: 'UCC Open Day', url: 'https://www.youtube.com/embed/53cUz5jHXR4' },
        { title: 'DCU Autism Friendly Student Open Days', url: 'https://www.youtube.com/embed/OsCXi8ybY70' },
        { title: 'UCG', url: 'https://www.youtube.com/embed/w-LR8Lhfc5I' },
        { title: 'TUD', url: 'https://www.youtube.com/embed/augHmMxrPwA' },                                                                  
    ], vid => (
        <div className="help-vids centered-content" key={vid.title}>
            <div className="help-vid">
                <iframe 
                    width="300px"
                    height="200px"                                                             
                    src={vid.url} 
                    title={vid.title} 
                    frameBorder="0" 
                    allow="fullscreen"
                    preload="none"
                >                                
                </iframe>
            </div>                       
            <div className="vid-text">
                {vid.title}
            </div>
        </div>
    ))

const VidsRight = () => _.map([
        { title: 'ATU - Sligo', url: 'https://www.youtube.com/embed/V2rvQnI_bBc' },
        { title: 'SETU - Carlow', url: 'https://www.youtube.com/embed/gPuC-DU97wc' },
        { title: 'Trinity College', url: 'https://www.youtube.com/embed/dd-Aru-SUFQ' },
        { title: 'Griffith College', url: 'https://www.youtube.com/embed/xpt9CHxsN5I' },                                                                  
    ], vid => (
        <div className="help-vids centered-content" key={vid.title}>
            <div className="help-vid">
                <iframe 
                    width="300px"
                    height="200px"                                                             
                    src={vid.url} 
                    title={vid.title} 
                    frameBorder="0" 
                    allow="fullscreen"
                >                                
                </iframe>
            </div>                       
            <div className="vid-text">
                {vid.title}
            </div>
        </div>
    ))


const Notifications = () => {   

    const dispatch = useDispatch()
    const activeTab = useSelector(getActiveTab)
    const activeStartDate = useSelector(getActiveStartDate)
    const start = moment(activeStartDate).startOf(activeTab).unix()
    const end = moment(activeStartDate).endOf(activeTab).unix()  
    const {data, isFetching, isLoading } = useGetEventsQuery({start, end})
    const events = _.get(data, 'events')
    const colors = ["#FAE4CD","#C1F0BC","#FFFCBB","#FFD3DB","#B5EFDE","#D5D8DE","#FFFCBB"]
    const user = useSelector(getCurrentUser)
    const [ setStepsCompleted ] = useSetStepsMutation();     

    const currentWeek = colors.map((color, index) => {
        return {
            color: color,
            date: moment(activeStartDate).startOf('week').add(index, 'days'),
            events: _.filter(events, e => moment(e.event_date).isSame(moment(activeStartDate).startOf('week').add(index, 'days'))),
        }
    })   
    

    const tileContent = ({ activeStartDate, date, view }) => {

        const tileEvents = _.filter(events, e => moment(e.event_date).isSame(moment(date)))      
        const inCurrentMonth = moment(date).isBefore(moment(activeStartDate).startOf('month')) || 
            moment(date).isAfter(moment(activeStartDate).endOf('month')) 
        const color = inCurrentMonth ?  '#B3B3B3' : _.isEmpty(tileEvents) ? '#D5D8DE' : '#FAE4CD'       
        
        return (
            <div 
                className={classNames("tile", {'has-events': !_.isEmpty(tileEvents)})} 
                style={{backgroundColor: color}} 
                onClick={() => dispatch(openModal({modalName: 'events', modalData: tileEvents}))}
            >
                <div className="tile-date">
                    {moment(date).format('D')}                   
                </div> {
                    !_.isEmpty(tileEvents) && (                        
                        <div className="tile-events">                            
                            <Badge bg="primary">{_.size(tileEvents)}</Badge>
                        </div>
                    )
                }                
            </div>
        )       
    }
    
    useEffect(() => {    
        if(user) {
            const bitSet = _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[7] 
            if(!bitSet || bitSet === '0') {
                setStepsCompleted({step_completed: 7})
                .then(result => dispatch(updateStepsCompleted({steps_completed: _.get(result, ['data', 'steps_completed'])})))
                .catch(error => console.log(error))
            }
        }
    }, [user, dispatch, setStepsCompleted]);


    

    return (
        <Container fluid className="muvr-content notifications">
            <Row className="muvr-row top-row">
                <Col xs={{offset: 1, span: 10}}>
                    <div className="title">
                        <div className="main-title">Notification Centre</div>
                    </div>                   
                </Col>                
            </Row>
            <Row>
                <Col xs={12}>
                    <Tabs
                        id="dashboard-tabs"                       
                        justify
                        activeKey={activeTab}
                        onSelect={key => dispatch(setActiveTab(key))}
                    >                        
                        <Tab eventKey="week" title="Week">                            
                            <div className="week">
                                <Row>
                                    <Col xs={{offset: 2, span: 8}} md={{offset: 4, span: 4}}>
                                        <div className={classNames("cal-nav-btn", "back-btn", {disabled: isLoading || isFetching})}>
                                            <SlArrowUp onClick={() => dispatch(setActiveStartDate(moment(activeStartDate).subtract(1, activeTab === 'week' ? 'W' : 'M').format()))}/>                                            
                                        </div>
                                    </Col>
                                </Row>        
                                <Row>
                                    <Col xs={{offset: 2, span: 8}} md={{offset: 4, span: 4}}>
                                        <div className="month-name">
                                            {_.toUpper(moment(activeStartDate).format('MMMM'))}
                                        </div>
                                    </Col>
                                </Row>            
                                <Row>
                                    <Col className="d-none d-lg-block" lg={{offset: 0, span: 4}}>
                                        {
                                            <VidsLeft />
                                        }
                                    </Col>
                                    <Col className="centered-content" md={{offset: 4, span: 4}} lg={{offset: 0, span: 4}}>
                                    {
                                        currentWeek.map((day, index) => (                                     
                                            <div className="day" key={index}>
                                                <div className="day-name">{day.date.format('dddd')}</div>
                                                <Card 
                                                    className={classNames('day-card', {'has-events': !_.isEmpty(day.events)})} 
                                                    style={{backgroundColor: day.color}} 
                                                >
                                                    <Card.Body>
                                                        <div className="dom">{day.date.format('DD')}</div>
                                                        <div className="event-holder">   
                                                            {
                                                                !_.isEmpty(day.events) && (
                                                                    <Button 
                                                                        size="lg" 
                                                                        variant="secondary"
                                                                        onClick={() => dispatch(openModal({modalName: 'events', modalData: day.events}))}
                                                                    >     
                                                                        Events                       
                                                                        <Badge bg="primary">{_.size(day.events)}</Badge>
                                                                    </Button>  
                                                                )
                                                            }  
                                                        </div>
                                                    </Card.Body>                                                                                                        
                                                </Card>  
                                            </div>                                            
                                        ))
                                    }        
                                    </Col>
                                    <Col className="d-none d-lg-block" lg={{offset: 0, span: 4}}>
                                        <VidsRight />
                                    </Col>
                                </Row>             
                                <Row>
                                    <Col>
                                        <div className={classNames("cal-nav-btn", {disabled: isLoading || isFetching})}>
                                               <SlArrowDown onClick={() => dispatch(setActiveStartDate(moment(activeStartDate).add(1, activeTab === 'week' ? 'W' : 'M').format()))}/>                                            
                                        </div>
                                    </Col>
                                </Row>                
                            </div>    
                        </Tab>
                        <Tab eventKey="month" title="Month">
                            <div className="month">                               
                                <Row>
                                    <Col xs={{offset: 4, span: 4}}>
                                        <div className={classNames("cal-nav-btn", "back-btn", {disabled: isLoading || isFetching})}>
                                            <SlArrowUp onClick={() => dispatch(setActiveStartDate(moment(activeStartDate).subtract(1, activeTab === 'week' ? 'W' : 'M').format()))}/>                                            
                                        </div>
                                    </Col>
                                </Row>  
                                <Row>
                                    <Col xs={{offset: 4, span: 4}}>
                                        <div className="month-name">
                                            {_.toUpper(moment(activeStartDate).format('MMMM'))}
                                        </div>
                                    </Col>
                                </Row>             
                                <Row>  
                                    <Col xs={{span:12}} md={{span:8, offset: 2}} lg={{span:6, offset: 3}}>
                                        <Calendar 
                                            formatDay={(locale, date) => moment(date).format('D')}
                                            tileContent={tileContent}                                            
                                            onActiveStartDateChange={({action, activeStartDate, value, view}) => dispatch(setActiveStartDate(moment(activeStartDate).format()))}
                                            value={activeStartDate}
                                            showNavigation={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{offset: 4, span: 4}}>
                                        <div className={classNames("cal-nav-btn", {disabled: isLoading || isFetching})}>
                                               <SlArrowDown onClick={() => dispatch(setActiveStartDate(moment(activeStartDate).add(1, activeTab === 'week' ? 'W' : 'M').format()))}/>                                            
                                        </div>
                                    </Col>
                                </Row> 
                            </div>
                        </Tab>                       
                    </Tabs>
                </Col>
            </Row>
        </Container>       
    )
}

export default Notifications