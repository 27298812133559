import { Card, Popover, OverlayTrigger, Button } from 'react-bootstrap'
import _ from 'lodash'
import classNames from 'classnames'
import { BsStarFill, BsStar } from 'react-icons/bs'
import { useUpdateUserCoursesMutation, useGetUserCoursesQuery } from '../../../features/user/userAPI'
import logo from '../../../assets/img/MetaUnivrCardLogo.png'


const CourseCard = props => {

    const [ updateUserCourses ] = useUpdateUserCoursesMutation();  
    const lastOfferPoints = _.get(_.last(_.get(props.course, ['offer_points'])), ['points'])     
    const handleCourserSelection = (course, isFlagged) => {   
        updateUserCourses({
            action: isFlagged ? 'add' : 'remove',
            course_id: course.id 
        })
    }     
    const userCourses = _.get(useGetUserCoursesQuery(), ['data', 'courses'])

    const importAll = r => {
        let images = {};
        _.map(r.keys(), (item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }    
    const images = importAll(require.context('../../../assets/img/collegeLogos', false, /\.(png|jpe?g)$/));
   
    const reqPopover =  (
        <Popover id="req-popover">            
            <Popover.Body>                
                {
                    _.map(props.course.requirements, requirement => (
                        <div className="req" key={requirement.id}>
                            <div className="req-name">
                                <strong>{_.startCase(_.replace(requirement.name, '_', ' '))}:</strong>
                            </div>
                            <div className="req-text">
                                {requirement.name === 'english_or_irish' ? _.replace(requirement.description, 'or', ' or ') : requirement.description.replace("Ancient Greek,Arabic,Bulgarian,Croatian,Czech,Danish,Dutch,Estonian,Finnish,French,German,Hungarian,Italian,Japanese,Latin,Latvian,Lithuanian,Maltese,Modern Greek,Polish,Portuguese,Romanian,Russian,Slovakian,Slovenian,Spanish,Swedish Modern Language", " Modern language")}
                            </div>
                        </div>
                    ))
                }
            </Popover.Body>
        </Popover>
    )

    const getOfferPoints = (points, additionalReqs) => {
        if(!additionalReqs) {
            return points
        }
        const portfolio = /PORTFOLIO/.test(_.toUpper(additionalReqs.description))
        const hpat = /HPAT/.test(_.toUpper(additionalReqs.description))           
        if(!portfolio && !hpat) {
            return points
        }
        const note = `(Including ${portfolio ? 'portfolio' : ''} ${portfolio && hpat ? ' and ' : ''}${hpat ? 'HPAT' : ''} points)`
        return `${points} ${note}`

    }
   
    return (       
        <Card className={classNames(
            "course-card", 
            props.course.suggestion_type, 
            {
                "short-card": props.course.is_international || props.course.is_apprenticeship || props.course.level === 5 || _.startsWith(props.course.course_code, 'DIRECT')} )}>
            <Card.Header>  
                {
                    !props.course.is_international && props.course.level > 5 && !props.course.is_apprenticeship && !_.startsWith(props.course.course_code, 'DIRECT') &&
                    <>
                        {
                            !!_.find(userCourses, c => props.course.id === c.id) ? 
                                <BsStarFill 
                                    className="fill" 
                                    onClick={() => handleCourserSelection(props.course, false)}
                                /> 
                                : 
                                <BsStar onClick={() => handleCourserSelection(props.course, true)}/>
                        }    
                        <div className="star-label">Add to CAO Simulator</div>       
                    </>                     
                }               
            </Card.Header>
            <Card.Body>   
                <div className="img-holder">
                    <Card.Img 
                        variant="top" 
                        src={
                            _.get(props.course, ['institution', 'logo']) ? 
                            _.get(images, [_.get(props.course, ['institution', 'logo'])]) :
                            logo
                        }
                    />
                </div>  
                <Card.Subtitle>
                    Course Title:
                </Card.Subtitle>   
                <Card.Text className="title">
                    {props.course.title}
                </Card.Text> 
                {
                    props.course.is_international && _.get(props.course, ['institution', 'name']) &&
                        <>
                            <Card.Subtitle>
                                Institution:
                            </Card.Subtitle>   
                            <Card.Text>
                                {props.course.institution.name}
                            </Card.Text>        
                        </>
                } 
                {
                    props.course.location &&
                        <>
                            <Card.Subtitle>
                                Location:
                            </Card.Subtitle>   
                            <Card.Text>
                                {props.course.location}
                            </Card.Text>    
                        </>
                }                     
                {
                    !props.course.is_international && !_.startsWith(props.course.course_code, 'DIRECT') &&
                        <>
                            <Card.Subtitle>
                                { props.course.is_apprenticeship ? 'Course Code' : (parseInt(_.get(props, ['course', 'course_code', 0])) ? 'PLC' : 'CAO') + ' Code:' }
                            </Card.Subtitle>   
                            <Card.Text>
                                { _.startsWith(_.get(props, ['course', 'course_code']), 'IN') ? "International" : _.get(props, ['course', 'course_code']) }
                            </Card.Text>    
                        </>
                }                     
                <Card.Subtitle>
                    Course Type:
                </Card.Subtitle>   
                <Card.Text>
                    {
                        props.course.is_apprenticeship ? 
                            'Apprenticeship' :
                            props.course.level < 9 ? 
                                props.course.level === 5 ? 'Post Leaving Certificate' : 'Undergraduate' : 
                                'PostGraduate'
                    }
                </Card.Text>      
                <Card.Subtitle>
                    NFQ Level:
                </Card.Subtitle>   
                <Card.Text>
                    {props.course.level}
                </Card.Text>                 
                {
                    !props.course.is_apprenticeship && lastOfferPoints &&
                        <>
                            <Card.Subtitle>
                                Minimum Points:
                            </Card.Subtitle>   
                            <Card.Text>
                                {getOfferPoints(lastOfferPoints, _.find(props.course.requirements, r => r.name === 'additional_requirements'))}
                            </Card.Text>             
                        </>
                }  
            </Card.Body>
            <Card.Footer>
                {
                    !_.isEmpty(props.course.requirements) && 
                        <OverlayTrigger trigger={['click']} placement="top" overlay={reqPopover}>
                            <Button size="sm" variant="secondary">Requirements</Button>
                        </OverlayTrigger>          
                }
                {
                    _.get(props, ['course', 'website']) &&

                        <Button size="sm" variant="secondary" onClick={() =>  window.open(props.course.website, "_blank")}>Website</Button>
                        
                }
            </Card.Footer>
        </Card>  
    )
}

export default CourseCard