import { Container, Row, Col } from 'react-bootstrap'
import angela from '../assets/img/angela.png'

const AboutUs = () => (
    <Container className="muvr-content about-us">
        <Row className="muvr-row top-row">
            <Col xs={12}> 
                <div className="about-title">             
                    Angela Curran
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} >
                <div className="about-text">  
                    <p>                 
                        Welcome to MyCareerVerse  by Angela, our team is dedicated to helping students unlock their full potential and navigate their educational journey with confidence. With a diverse range of qualifications and expertise, Angela brings a unique perspective to our organisation
                    </p>
                    <p>
                        Angela holds a primary degree in Business and IT, as well as a Masters in Guidance Counselling from DCU. Her educational background combines technology, management, and a deep understanding of human potential. With experience working in renowned companies such as Dell and various tech startups, Angela has honed her leadership and team development skills. Having lived in Ireland, Australia, and London, she possesses a global perspective on the world of work, allowing her to connect with students from diverse backgrounds
                    </p>
                    <p>
                        Driven by a genuine desire to help individuals achieve their goals, Angela transitioned to the educational space six years ago. As a member of the Institute of Guidance Counsellors of Ireland and the Psychological Society of Ireland, she stays updated with the latest research and practices in her field
                    </p>
                    <p>
                        At Belvedere College S.J, Angela has been instrumental in delivering comprehensive guidance programs. Her innovative "Transitioning into Secondary School" program, developed during her MSc Guidance Counselling research, revolutionised the approach to helping students settle into secondary school. Additionally, Angela has designed a specialised Study Skills Training program that equips students with essential tools for becoming confident and competent learners. These programs have been integrated into the curriculum and have achieved significant success in supporting students' transition into secondary school and academic success
                    </p>
                    <p>
                        Angela's extensive experience in management roles has allowed her to guide and develop young professionals, helping them reach their full potential. Her dedication to her profession and the success of her students is unwavering. She understands the challenges students face and provides personalised guidance to each individual
                    </p>
                    <p>
                        In 2021, Angela founded MyCareerVerse out of frustration with the lack of student-friendly and easily accessible information available for career exploration. The vision of MyCareerVerse is to simplify the process and provide students with the necessary tools and guidance to make informed decisions. Leveraging the power of augmented reality (AR), virtual reality (VR), and 360 videos, we utilise immersive technologies to support students in their career exploration journey. Our platform offers interactive experiences that bring careers to life, allowing students to gain a deeper understanding of various professions and industries
                    </p>
                    <p>
                        At MyCareerVerse, our mission is to support students in finding their true calling and setting them up for success. We are dedicated to making the journey of career exploration manageable and less daunting. By utilising Angela's expertise and the user-friendly platform she has created, along with the immersive technologies we employ, we empower students to make informed choices and set themselves on the right path from the start
                    </p>
                    <p>
                        If you are a student seeking guidance, support, and the tools to thrive academically and personally, you've come to the right place. Join us on this transformative journey, and let us help you unlock your true potential
                    </p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <div className="about-contact">                
                    Contact us acurran56@gmail.com                     
                </div>
                    <img src={angela} alt="angela"/>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <div className="about-tests">    
                    <div className="tests-title">     
                        Testimonials
                    </div>
                    <div className="test">
                        <div className="test-text">
                            The work you are doing with her is hugely increasing her confidence - we are very grateful to you
                        </div>
                        <div className="test-by">
                            - Mary C
                        </div>
                    </div>
                    <div className="test">
                        <div className="test-text">
                            I don't know what you said to him but he has completely changed, he is studying every evening and now highly motivated
                        </div>
                        <div className="test-by">
                            - Mary G
                        </div>
                    </div>
                    <div className="test">
                        <div className="test-text">
                            Many thanks for your helpful and engaging work with my son. He found it hugely beneficial as did I and has given him a great platform to work from as he considers his subject choice and future careers
                        </div>
                        <div className="test-by">
                            - John D
                        </div>
                    </div>
                    <div className="test">
                        <div className="test-text">
                            Very positive feedback.  She seems really energised and hopeful.  It was just what she needed.  Definitely saw the positivity coming through
                        </div>
                        <div className="test-by">
                            - Susan O
                        </div>
                    </div>
                    <div className="test">
                        <div className="test-text">
                            Thank you very much for everything.  I found the experience really helpful and informative
                        </div>
                        <div className="test-by">
                            - Diarmuid N
                        </div>
                    </div>
                    <div className="test">
                        <div className="test-text">
                            Thank you so much for the session.  I found it really helpful. I enjoyed the process and felt that it was self exploratory for me
                        </div>
                        <div className="test-by">
                            - Sinead H
                        </div>
                    </div>                   
                </div>                 
            </Col>
        </Row>  
    </Container>
)

export default AboutUs