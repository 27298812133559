import { useSelector, useDispatch } from 'react-redux'
import { getTranslation, openModal } from '../app/appSlice'
import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import Error from '../components/Error'
import { useRegisterMutation } from '../features/auth/authAPI'
import { useNavigate } from 'react-router-dom'


const Register = () => {   
    const dispatch = useDispatch()
    const usernameLabel = useSelector(getTranslation('register.username.label'))
    const emailLabel = useSelector(getTranslation('register.email.label'))
    const emailConfirmLabel = useSelector(getTranslation('register.emailConfirm.label'))
    const passwordLabel = useSelector(getTranslation('register.password.label'))
    const passwordConfirmLabel = useSelector(getTranslation('register.passwordConfirm.label'))
    const mobileLabel = useSelector(getTranslation('register.moblile.label'))
    const createAccBtnLabel = useSelector(getTranslation('register.creatAccount.btn'))
    const navigate = useNavigate()
    const [customError, setCustomError] = useState(null)
    const [form, updateForm] = useState({
        username: '',
        email: '', 
        emailConfirm: '',
        password: '',
        passwordConfirm: '',
        mobile: ''
    }); 
    const [ termsAgreed, updateTermsAgreed ] = useState(null)  
    const [ register, data ] = useRegisterMutation(); 

    const submitForm = e => {        
        e.preventDefault() 
        if (form.password !== form.passwordConfirm) {
            setCustomError('Password mismatch')
            return
        }
        if (form.email !== form.emailConfirm) {
            setCustomError('Email mismatch')
            return
        }
        if (!termsAgreed) {
            setCustomError('Must agree to terms')
            return
        }        
        register(_.pick(form, ['email', 'password', 'username', 'mobile']))
    }

    useEffect(() => {        
        if (_.get(data, ['isSuccess'])) {
            navigate('/')
        }
        else if (_.get(data, ['isError'])) {
            if(_.get(data, ['error', 'status']) === 409) {
                setCustomError('Email already exists')
            }
        }
    }, [data, navigate])

    return (
        <>
            <Container className="muvr-content register">
                <Row className="top-row">
                    <Col xs={12} className="centered-content">
                        <div className="form-holder backdrop decorator">                      
                            <Form className="muvr-form" onSubmit={submitForm}>
                                <div className="muvr-form-body">
                                    <Form.Group className="mb-3" controlId="formUsername">
                                        <Form.Label>{usernameLabel + ' :'}</Form.Label>
                                        <Form.Control 
                                            size="lg"
                                            value={form.username}
                                            onChange={e => updateForm(_.assign({}, form, { username: _.get(e, ['target', 'value']) }))}
                                        />                            
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>{emailLabel + ' :'}</Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            size="lg"
                                            value={form.email}
                                            onChange={e => updateForm(_.assign({}, form, { email: _.get(e, ['target', 'value']) }))}
                                        />                            
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formConfirmEmail">
                                        <Form.Label>{emailConfirmLabel + ' :'}</Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            size="lg"
                                            value={form.emailConfirm}
                                            onChange={e => updateForm(_.assign({}, form, { emailConfirm: _.get(e, ['target', 'value']) }))}
                                        />                            
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <Form.Label>{passwordLabel + ' :'}</Form.Label>
                                        <Form.Control 
                                            type="password" 
                                            size="lg"
                                            value={form.password}
                                            onChange={e => updateForm(_.assign({}, form, { password: _.get(e, ['target', 'value']) }))}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formConfirmPassword">
                                        <Form.Label>{passwordConfirmLabel + ' :'}</Form.Label>
                                        <Form.Control 
                                            type="password" 
                                            size="lg"
                                            value={form.passwordConfirm}
                                            onChange={e => updateForm(_.assign({}, form, { passwordConfirm: _.get(e, ['target', 'value']) }))}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formMobile">
                                        <Form.Label>{mobileLabel + ' :'}</Form.Label>
                                        <Form.Control 
                                            size="lg"
                                            value={form.mobile}
                                            onChange={e => updateForm(_.assign({}, form, { mobile: _.get(e, ['target', 'value']) }))}
                                        />
                                    </Form.Group>
                                    <div className="form-error">
                                        {customError && <Error>{customError}</Error>}
                                    </div>
                                    <div className="terms">
                                        <div className="terms-title">
                                            <span>{'By Registering you agree to our '}</span>
                                            <span className="anchor-btn" onClick={() => dispatch(openModal({modalName: 'terms'}))}>
                                                Terms of Use and Privacy Policy
                                            </span>
                                        </div>                           
                                        <Form.Check 
                                            type="checkbox" 
                                            label="I confirm I am 16 years of age or over" 
                                            id="terms-adult"
                                            checked={termsAgreed === 'adult'}
                                            onChange={e => updateTermsAgreed(termsAgreed === 'adult' ? undefined : 'adult')}
                                        />                            
                                        <div className="terms-or">OR</div>                              
                                        <Form.Check 
                                            type="checkbox" 
                                            label="I am under 16 years of age and have obtained parental consent to use this app."
                                            id="terms-child"
                                            checked={termsAgreed === 'child'}
                                            onChange={e => updateTermsAgreed(termsAgreed === 'child' ? undefined : 'child')}
                                        />                         
                                    </div>
                                </div>                            
                                <div className="form-actions">
                                    <Button 
                                        variant="secondary" 
                                        type="submit" 
                                        size="lg"
                                        disabled={_.some(_.pick(form, ['email', 'password', 'username', 'mobile', 'emailConfirm', 'passwordConfirm']), field => field === '')}>
                                        {createAccBtnLabel}
                                    </Button>
                                </div>
                            </Form>      
                        </div>                        
                    </Col>                
                </Row>
            </Container>        
        </>
    )
}

export default Register