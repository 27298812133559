import { Button, Card } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { useEffect } from 'react'
import { useGetQuestionsQuery, useUpdateInterestProfileMutation } from './interestProfilerAPI'
import { incrementScore, getProfile, setProfile, updateQuestions, getCurrentQuestion, beginQuiz, retakeQuiz  } from './interestProfilerSlice'
import { updateStepsCompleted } from '../auth/authSlice'


const InterestProfilerComponent = () => {
    const dispatch = useDispatch()
    const questions = _.get(useGetQuestionsQuery(), ['data', 'questions'])
    const question = useSelector(getCurrentQuestion)
    const profile = useSelector(getProfile)
    const [ updateProfile ] = useUpdateInterestProfileMutation(); 

    const importAll = r => {
        let images = {};
        _.map(r.keys(), (item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }
    const images = importAll(require.context('../../assets/img/questionCard', false, /\.(png|jpe?g|svg)$/));
   
    const answers = [
        {
            label: 'Strongly Dislike',
            buttonLabel: 'SD',
            color: '#E71010'
        },
        {
            label: 'Dislike',
            buttonLabel: 'D',
            color: '#FF650F'
        },
        {
            label: 'Indifferent',
            buttonLabel: 'I',
            color: '#454A4B'
        },
        {
            label: 'Like',
            buttonLabel: 'L',
            color: '#54C71F'
        },
        {
            label: 'Strongly Like',
            buttonLabel: 'SL',
            color: '#005504'
        }
    ]

    useEffect(() => {
        if(questions) {                      
            dispatch(updateQuestions(questions))
        }
    }, [questions, dispatch]);

    useEffect(() => {       
        if(_.get(profile, ['update'])) {
            updateProfile(_.omit(profile, ['update', 'complete']))
            .then(response => {
                dispatch(setProfile(_.get(response, ['data', 'interest_profile'])))
                dispatch(updateStepsCompleted({steps_completed: _.get(response, ['data', 'steps_completed'])}))
            })
            .catch(error => console.error('Update Error', error))
        }
    }, [profile, dispatch, updateProfile]);

    return (
        <div className="questions">   
            {
                question && !_.get(profile, ['complete']) &&
                    <Card className={_.head(_.shuffle(['realistic', 'investigative', 'artistic', 'social', 'enterprising', 'conventional']))}>
                        <Card.Img 
                            variant="top" 
                            src={_.get(images, [question.image])}
                        />    
                        <Card.Body>
                            <Card.Text>
                                {question.text}    
                            </Card.Text>                                    
                        </Card.Body>
                        <Card.Footer>                               
                            {                                
                                _.map(answers, (answer, index) => (      
                                    <div
                                        className="answer" 
                                        key={index}
                                        style={{color: answer.color}}
                                    >                                                        
                                        <Button 
                                            variant="outline-primary"
                                            onClick={() => dispatch(incrementScore({
                                                category: question.categoryName, 
                                                score: index + 1
                                            }))}
                                            style={{
                                                color: answer.color,
                                                borderColor: answer.color
                                            }}
                                        >
                                            {answer.buttonLabel}
                                        </Button>
                                        <div className="answer-label">    
                                            <span>{answer.label}</span>
                                        </div>            
                                    </div>                                                                            
                                ))
                            }                               
                        </Card.Footer>
                    </Card>   
            }
            {
                _.get(profile, ['complete']) &&
                    <div>
                       <div className="banner gradient-text">QUIZ COMPLETE</div>
                       <Button variant="outline-primary" onClick={() => dispatch(retakeQuiz())}>Retake Quiz</Button>
                    </div>
            } 
            {
                !question && !_.get(profile, ['complete']) &&
                    <div>
                        <div className="banner gradient-text">BEGIN QUIZ</div>
                        <Button variant="outline-primary" onClick={() => dispatch(beginQuiz())}>Take Quiz</Button>
                    </div>
            }                        
        </div>
    )
}

export default InterestProfilerComponent