import { Button, Modal, Container, Row, Col, Card } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { isModalOpen, closeModal } from '../../app/appSlice'
import _ from 'lodash'
import { CircleX } from 'akar-icons';    
import { getModalData } from '../../app/appSlice'
import moment from 'moment'


function EventsModal() {

	const modalOpen = useSelector(isModalOpen('events'))
	const dispatch = useDispatch()
	const events = useSelector(getModalData('events'))
	return (
		<Modal 
			className="events-modal" 
			show={modalOpen} 
			onHide={() => dispatch(closeModal())}
			centered	
			size="lg"		
		>
			<Modal.Header>
				<div className="close-btn-holder">
					<button 
						className='btn btn-close' 
						onClick={() => dispatch(closeModal())}						
					>
						<CircleX size="50" strokeWidth="1"/>
					</button>
				</div>
				<Modal.Title>{moment(_.get(_.head(events), ['event_date'])).format('dddd Do MMMM YY')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container className="events">
					<Row>
						{
							_.map(events, event => {							
								const logo = require(`../../assets/img/${_.get(event, ['institution', 'logo']) ?  
									`collegeLogos/${event.institution.logo}` : 
									'MetaUnivrLogo.png'}`)
								return (
									<Col className="centered-content" key={event.id}>
										<Card className="event-card" >
											<Card.Header>
												<div className="img-holder">
													<Card.Img 
								                        variant="top" 
								                        src={logo}
								                    />
								                </div>
											</Card.Header>
											<Card.Body>
												<Card.Subtitle>
								                    Event name:
								                </Card.Subtitle>   
								                <Card.Text className="title">
								                    {event.name}
								                </Card.Text>
								                <Card.Subtitle>
								                    Provider:
								                </Card.Subtitle>   
								                <Card.Text className="title">
								                    {event.institution.name}
								                </Card.Text>   
								                <Card.Subtitle>
								                    Location:
								                </Card.Subtitle>   
								                <Card.Text className="title">
								                    {event.location}
								                </Card.Text>   								                						                
											</Card.Body>
											<Card.Footer>
												{
									                _.get(event, ['url']) &&
									                    <Button variant="primary" onClick={() =>  window.open(event.url, "_blank")}>Website</Button>
									            }
											</Card.Footer>
										</Card>
									</Col>
								)
							})
						}
					</Row>
				</Container>				
			</Modal.Body>
			<Modal.Footer>				
				<Button variant="info" onClick={() => dispatch(closeModal())}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default EventsModal