import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
	profile : {}
}

const categories = {
	1: { categoryName: 'realistic'},
	2: { categoryName: 'investigative'},
	3: { categoryName: 'artistic'},
	4: { categoryName: 'social'},
	5: { categoryName: 'enterprising'},
	6: { categoryName: 'conventional'}
}

const getCode = unorderedCode => {
	const holland_codes = [
		'RIA', 'RIS', 'RIE', 'RIC', 'RAE', 'RAS', 'RAC', 'RSE', 'RSC', 'REC', 
		'IAS', 'IAE', 'IAC', 'ISE', 'ISC', 'ASC', 'IEC', 'ASE', 'AEC', 'SEC'
	]
	return _.find(holland_codes, hc => _.every(hc, letter => _.indexOf(unorderedCode, letter) > -1))
}

const interestProfilerSlice = createSlice({
	name: 'interestProfiler',
	initialState,
	reducers: {
		incrementScore: (state, action) => {			
			const { category, score  }= action.payload	
			if(category) {	
				state.profile[category] = _.get(state.profile, [category]) ? _.concat(_.get(state.profile, [category]), score) : [score]
			}								
			if(state.currentIndex === _.size(state.questions) -1 ) {
				_.forEach(_.keys(state.profile), key => {
					state.profile[key] = (_.reduce(state.profile[key], (sum, n) => sum + n) / _.size(state.profile[key])).toFixed(2)
				})	
				const catKeys = _.filter(_.map(_.range(1, 7), index => _.get(categories, [index, 'categoryName'])), k => _.indexOf(_.keys(state.profile), k) > -1)
				state.profile.code = getCode(_.reduce(_.map(_.take(_.orderBy(catKeys, [k => _.get(state.profile, [k])], ['desc']), 3), best => _.toUpper(best[0])), (accumulator, letter) => accumulator + letter))
				state.profile.update = true;				
			}
			else {
				state.currentIndex = state.currentIndex + 1
			}
		},
		updateQuestions: (state, action) => {
			const questions = action.payload
			state.questions = _.shuffle(_.map(questions, q => _.assign({}, q, _.get(categories, [q.category]))))
		},
		setProfile: (state, action) => {
			const profile = action.payload
			state.profile = _.assign({}, profile, {complete: true})
		},
		beginQuiz: state => {
			state.currentIndex = 0
		},
		retakeQuiz: state => {
			state.currentIndex = 0;
			state.profile = {}
		},
		setUserCareers: (state, action) => {
			state.userCareers = action.payload
		}	
	}
})

export const { incrementScore, updateQuestions, setProfile, beginQuiz, retakeQuiz, setUserCareers } = interestProfilerSlice.actions
export const getProfile = state => state.interestProfilerSlice.profile
export const getCurrentQuestion = state => _.get(state.interestProfilerSlice, ['questions', _.get(state, ['interestProfilerSlice', 'currentIndex'])])
export const isProfileComplete = state => _.get(state.interestProfilerSlice, ['profile', 'complete'])
export const getUserCareers = state => _.get(state.interestProfilerSlice, ['userCareers'])
export default interestProfilerSlice.reducer