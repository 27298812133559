import { Row, Col, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { 
    getSelectedSeason, 
    updateSubject, 
    updateLevel,
    updateGrade,
    selectSeason,
    resetSeason, 
    setExamResult,
    setExamResults
} from './calculatorSlice'
import { useGetSeasonsQuery, useGetSubjectsQuery, useGetExamResultsQuery, useUpdateExamResultMutation, useGetGradesQuery, useGetLevelsQuery } from './calculatorAPI'
import { useEffect } from 'react'
import Select from 'react-select'
import { getCurrentUser, updateStepsCompleted } from '../auth/authSlice'
import classNames from 'classnames'


const CalculatorComponent = () => {
    const dispatch = useDispatch()    
    const seasons = _.get(useGetSeasonsQuery(), ['data', 'seasons'])    
    const subjects = _.get(useGetSubjectsQuery(), ['data', 'subjects'])
    const selectedSeason = useSelector(getSelectedSeason)
    const user = useSelector(getCurrentUser)  
    const examResults = _.get(useGetExamResultsQuery(undefined, {skip: !user}), ['data', 'exam_results'])
    const [ updateExamResult ] = useUpdateExamResultMutation()
    const grades = _.get(useGetGradesQuery(), ['data', 'grades'])
    const levels = _.get(useGetLevelsQuery(), ['data', 'levels'])
    
    useEffect(() => {
        if(examResults) {     
            dispatch(setExamResults(examResults))
        }
    }, [examResults, dispatch]);
    
    useEffect(() => {
        if(!selectedSeason && seasons && subjects) {
            dispatch(selectSeason({
                season: _.head(seasons),
                mandatory: _.filter(subjects, s => s.mandatory)
            }))
        }      
    }, [seasons, selectedSeason, subjects, dispatch]);

    const postGrade = (formRow, index) => {    
        let levelId =  _.get(formRow, ['level', 'id'])
        const gradeId = _.get(formRow, ['grade', 'id'])
        if(!levelId && gradeId) {
            levelId = _.get(_.find(levels, l => l.name[0] === formRow.grade.name[0]), ['id'])
        }       
        updateExamResult({
            exam_result_id: _.get(formRow, ['id']),
            subject_id:  _.get(formRow, ['subject', 'id']),
            level_id: levelId,
            grade_id: gradeId,
            season_id: selectedSeason.id,   
            form_index: index 
        })
        .then(result => {
            dispatch(setExamResult(_.get(result, ['data'])))
            dispatch(updateStepsCompleted({steps_completed: _.get(result, ['data', 'steps_completed'])}))
        })       
        .catch(error => console.error('Update Error', error))
    }

    const selectStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {           
            return {
                ...styles, 
                'backgroundColor': '#16161E;',          
                ':hover' : { 
                    backgroundColor: '#7157F8'
                },                
            };
        }        
    };

    return (
        <div className="calculator-form backdrop">
            <Row>            
                {
                    _.map(seasons, season => (
                        <Col xs={12} md={6} lg={3} key={season.id}>
                            <Button 
                                className={classNames('season-btn')} 
                                variant={season.id === _.get(selectedSeason, ['id']) ? 'primary' : 'outline-primary'}
                                onClick={() => dispatch(selectSeason({
                                    season: _.cloneDeep(season),
                                    mandatory: _.filter(subjects, s => s.mandatory)                          
                                }))}
                            >
                                {season.name}    
                            </Button>
                        </Col>
                    ))
                }
            </Row>
            {
                _.map(_.get(selectedSeason, ['resultSet']), (formRow, index) => (
                    <Row key={index} className="form-row-container">
                        <Col xs={12} md={6} lg={3}>
                            <Select            
                                className="basic-single"
                                classNamePrefix="muvr-select"
                                value={_.get(formRow, ['subject', 'name']) ? {
                                        label: formRow.subject.name,
                                        value: formRow                                       
                                    } 
                                    : false
                                }
                                isSearchable={true}
                                onChange={ selected => user && _.get(formRow, ['grade']) ?   
                                        postGrade(_.assign({}, formRow, {subject: selected.value}), index)
                                    :
                                        dispatch(updateSubject({
                                            subject: selected.value,
                                            index
                                        }))
                                }
                                options={_.map(_.filter(subjects, s => !_.find(selectedSeason.resultSet, ss => _.get(ss, ['subject', 'id']) === s.id)), s => {return {label: s.name, value: s}})}    
                                isDisabled={_.get(formRow, ['subject', 'mandatory']) && _.get(formRow, ['subject', 'name']) !== 'Irish'}
                                placeholder="Select Subject"
                                styles={selectStyles}
                            /> 
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Select            
                                className="basic-single"
                                classNamePrefix="muvr-select"
                                value={_.get(formRow, ['level', 'name']) ? 
                                    _.get(formRow, ['subject','name']) === 'LCVP' ? 
                                            {
                                                label: 'Common Level',
                                                value: false
                                            }
                                        :
                                            {
                                                label: formRow.level.name,
                                                value: formRow.level
                                            }                                        
                                    : false
                                }                             
                                onChange={ selected => dispatch(updateLevel({
                                    level: _.cloneDeep(selected.value),
                                    index: index
                                }))}
                                options={_.get(formRow, ['subject','name']) === 'LCVP' ? [] : _.map(_.get(formRow, ['subject','levels']), l => {return {label: l.name, value: l}})}     
                                placeholder={_.get(formRow, ['subject','name']) === 'LCVP' ? "Common Level" : "Select Level"}   
                                styles={selectStyles}
                                isSearchable={false}
                                isDisabled={_.get(formRow, ['subject','name']) === 'LCVP'}
                            />                           
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <Select            
                                className="basic-single inline"
                                classNamePrefix="muvr-select"
                                value={_.get(formRow, ['grade', 'name']) ? {
                                        label: _.get(formRow, ['subject','name']) === 'LCVP' ? 
                                                formRow.grade.name === 'H2' ? 'Distinction' : 
                                                    formRow.grade.name === 'H6' ? 'Merit' :
                                                        formRow.grade.name  === 'O4' ? 'Pass' :
                                                            false
                                            :
                                                formRow.grade.name,
                                        value: formRow.grade
                                    } 
                                    : false
                                }                             
                                onChange={ selected => user ? 
                                        postGrade(_.assign({}, formRow, {grade: selected.value}), index)
                                    :
                                        dispatch(updateGrade({
                                            grade: _.cloneDeep(selected.value),
                                            index: index
                                        }))
                                }
                                options={
                                    _.get(formRow, ['subject','name']) === 'LCVP' ? 
                                        [
                                            { label: 'Distinction', value: _.find(grades, g => g.name === 'H2') },
                                            { label: 'Merit', value: _.find(grades, g => g.name === 'H6') },
                                            { label: 'Pass', value: _.find(grades, g => g.name === 'O4') }
                                        ] 
                                    : 
                                        _.map(_.get(formRow, ['level', 'grades']), g => {return {label: g.name, value: g}})
                                }     
                                placeholder="Select Grade"   
                                styles={selectStyles}
                                isSearchable={false}
                            />                           
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="points">
                                {formRow.grade && `${formRow.points} points`}
                            </div>
                        </Col>                        
                    </Row>                   
                ))
            } 
            <Row>
                <Col xs="8">
                    <Button 
                        className="reset-btn"
                        onClick={() => dispatch(resetSeason({mandatory: _.filter(subjects, s => s.mandatory)}))}
                        size="lg"
                        variant="outline-primary"
                    >
                        Reset Form
                    </Button>
                </Col>
                <Col xs="4">
                { 
                    _.get(selectedSeason, ['totalPoints']) ? 
                        <div className="total-points">
                            <span className="tp-label">
                                Total Points:
                            </span>
                            {_.get(selectedSeason, ['totalPoints'])}
                        </div>
                    :
                        <></>
                }
                </Col>
            </Row>          
        </div>
    )
}

export default CalculatorComponent