import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
	pathways: {},
	courses: {},
	apprenticeships: {},
	// locations: [],
	selectedLocations: [],
	currentPage: 1,	
	levels: [
		'level8',
		'level7',
		'level6',
		'level5',
		'level6a',
		'international',
		'direct',
	],
	tabsEnabled: []
}


const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {		
		dismissApprenticeship: (state, action) => {
			const { apprenticeship, career } = action.payload
			state.apprenticeships[career.id] = _.map(state.apprenticeships[career.id], c =>  c.id === apprenticeship.id ? _.assign({}, c, {dismissed: true}) : c)		
		},	
		dismissPathway: (state, action) => {			
			const { pathway, career } = action.payload
			state.pathways[career.id] = _.map(state.pathways[career.id], c =>  c.id === pathway.id ? _.assign({}, c, {dismissed: true}) : c)
		},			
		setCourses: (state, action) => {
			const { courses, career } = action.payload			
			let careerCourses = {}
			_.forEach(state.levels, l => careerCourses[l] = [])	

			_.forEach(courses, course => {
				if(_.startsWith(course.course_code, 'DIRECT')) {
					careerCourses['direct'].push(course)
				}else if(course.is_international){
					careerCourses['international'].push(course)
				}else if(course.apprenticeship){
					careerCourses['level6a'].push(course)
				}else {
					const key = `level${course.level}`
					careerCourses[key].push(course)
				}
			})
			state.courses[career.id] = careerCourses
		},
		selectLocation: (state, action) => {
			state.selectedLocations = action.payload
		},
		setCurrentPage: (state, action) => {
			const { page } = action.payload
			state.currentPage = page
		},		
		setScreenWidth: (state, action) => {
			const { screenWidth } = action.payload
			const getScreenSize = (width) => {
		        let screenSize = 'xs'
		        if(width >= 1400) {
		            screenSize = 'xxl'
		        }else if(width >= 1200) {
		            screenSize = 'xl'
		        }else if(width >= 992) {
		            screenSize = 'lg'
		        }
		        else if(width >= 768) {
		            screenSize = 'md'
		        }
		        else if(width >= 576) {
		            screenSize = 'sm'
		        }
		        return screenSize
		    }
			state.screenWidth = getScreenSize(screenWidth)

			let perSlide = 1
			switch(state.screenWidth) {
				case 'xs':
				case 'sm': perSlide = 1; break
				case 'md': perSlide = 2; break
				case 'lg': 
				case 'xl': perSlide = 3; break
				case 'xxl': perSlide = 4; break
				default: break;
			}
			state.coursesPerPage = perSlide
		},
		setTabEnabled: (state, action) => {
			const { career_index, level } = action.payload
			if(_.indexOf(state.tabsEnabled[career_index], level) === -1) {
				state.tabsEnabled[career_index] = _.concat(state.tabsEnabled[career_index], [level])
			}
		},
		setTabDisabled: (state, action) => {
			const { career_index, level } = action.payload
			console.log("disable", career_index, level)
			
			//state.tabsEnabled[career_index] = _.filter(state.tabsEnabled[career_index], te => te != level)
			state.tabsEnabled[career_index] = _.filter(state.tabsEnabled[career_index], te => te !== level)
		},
		resetTabsEnabled: (state, action) => {
			const { size } = action.payload
			state.tabsEnabled = _.map(_.range(size), i => [])
		}
	}
})

export const { 
	setCourses,
	selectLocation,	
	setCurrentPage,
	setScreenWidth,
	setTabEnabled,
	resetTabsEnabled,
	setTabDisabled
} = dashboardSlice.actions
export const getCourses = (state, args) => {
	const { careerId, level } = args	
	let courses = _.get(state.dashboardSlice, ['courses', careerId, level])
	if(_.size(state.dashboardSlice.selectedLocations) > 0) {
		courses = _.filter(courses, course => !course.region || !!_.find(state.dashboardSlice.selectedLocations, course.region))	
	}	
	return _.chunk(courses, _.get(state.dashboardSlice, ['coursesPerSlide']))	
}
export const getSelectedLocations = state => _.get(state.dashboardSlice, ['selectedLocations'])
export const getCurrentPage = state => _.get(state.dashboardSlice, ['currentPage'])
export const getScreenWidth = state => _.get(state.dashboardSlice, ['screenWidth'])
export const getLevels = state => _.get(state.dashboardSlice, ['levels'])
export const getCoursesPerPage = state => _.get(state.dashboardSlice, ['coursesPerPage'])
export const getTabsEnabled = (state, index) => _.get(state.dashboardSlice, ['tabsEnabled', index])
export default dashboardSlice.reducer