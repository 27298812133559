import { Container, Row, Col, Carousel, Card } from 'react-bootstrap'
import _ from 'lodash'
import image from '../assets/img/Group 38194.png'
import { useGetCareerToursQuery } from '../features/careerTour/careerTourAPI'
import { useSetStepsMutation } from '../features/user/userAPI' 
import { getCurrentUser, updateStepsCompleted } from '../features/auth/authSlice'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'


const CampusTours = () => {
    const institutions = _.get(useGetCareerToursQuery(), ['data', 'institutions'])   
    const importAll = r => {
        let images = {};
        _.map(r.keys(), (item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }    
    const logos = importAll(require.context('../assets/img/collegeLogos', false, /\.(png|jpe?g)$/));    
    const colors = ["#AFDBF3", "#CCCBF0", "#FAE4CD", "#C1F0BC", "#FFD3DB", "#B5EFDE", "#DBE2E2", "#AFDBF3"]
    const altLogos = {
        '4NCAD.png': 'ncadalt.jpg',
        '20DCU.jpg': 'DCUALT.jpg'
    }
    const user = useSelector(getCurrentUser)
    const [ setStepsCompleted ] = useSetStepsMutation(); 
    const dispatch = useDispatch()

    useEffect(() => {    
        if(user) {
            const bitSet = _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[4] 
            if(!bitSet || bitSet === '0') {
                setStepsCompleted({step_completed: 4})
                .then(result => dispatch(updateStepsCompleted({steps_completed: _.get(result, ['data', 'steps_completed'])})))
                .catch(error => console.log(error))
            }
        }
    }, [user, dispatch, setStepsCompleted]);

    return (       
        <Container fluid className="muvr-content campus-tours">
            <Row className="top-row muvr-row">
                <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 1}} className="caption-col">
                    <div className="caption">
                        <div className="caption-title">Virtual Campus Tours</div>                     
                        <div className="caption-text">
                            Immerse yourself in virtual reality to explore college campuses and facilities, giving you a glimpse of the campus atmosphere
                        </div>
                    </div>                    
                </Col>
                <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 1}}>    
                    <div className="img-holder">                  
                        <img src={image} alt="virtual campus tours" /> 
                    </div>                  
                </Col>
            </Row>
            <Row className="muvr-row">
                <Col className="centered-content">
                    <div className="banner-group">
                        <div className="banner-lg">DIVE INTO VR</div>
                        <div className="banner-inset">Explore 360 Views of Colleges</div>
                    </div>
                    <div className="sub-banner gradient-text">                       
                        Library of VR College Experiences
                    </div>
                    <div className="lib-description">                       
                        Below is a library of VR experiences where you can have a 360 view of different college campuses
                    </div>
                </Col>
            </Row>   
            <Row className="muvr-row">
                <Col>           
                    <Carousel
                        interval={null} 
                        fade 
                        indicators
                        controls
                    >                       
                        {
                            institutions && _.map(_.range(institutions.length / 8), page => (
                                <Carousel.Item key={page}>
                                    <div className="legend">Click experience to begin</div>
                                    <Container>
                                        <Row className="tours">
                                            {
                                                _.slice(institutions, page * 8, (page + 1) * 8).map((institution, index) =>(
                                                    <Col xs={12} md={6} lg={3} key={institution.id}>
                                                        <Card                                                  
                                                            onClick={() => window.open(institution.tour_url, "_blank")}
                                                            style={{backgroundColor: colors[index]}}
                                                        >
                                                            <Card.Body>
                                                                <div className="img-holder">
                                                                    <Card.Img 
                                                                        variant="top" 
                                                                        src={_.indexOf(_.keys(altLogos), institution.logo) > -1 ? logos[altLogos[institution.logo]] : _.get(logos, [institution.logo])}                                                                 
                                                                    />
                                                                </div> 
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Card.Text>
                                                                    {institution.name}
                                                                </Card.Text>  
                                                            </Card.Footer>                                                     
                                                        </Card>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </Container>
                                </Carousel.Item>
                            ))
                        }                                             
                    </Carousel>
                </Col>
            </Row>       
        </Container>  
    )
}

export default CampusTours