export const enIE = {
	'login':'Login',
	'login.btn':'Login/Register',
	'login.username': 'Username',
	'login.username.placeholder': 'Enter Username or Email',
	'login.password': 'Password',
	'login.password.placeholder': 'Enter Password',
	'login.register': 'Create new account',
	'login.forgotPassword': 'Forgot password',
	'register': "register",
	'home.caption.title': 'MyCareerVerse',
	'home.caption.subtitle': 'Organising your pathway.',
	'home.caption.text': 'MyCareerVerse is a place to log grades, track progression, generate alternative college pathways,  experience VR careers and college campuses',
	'home.categories.label' : 'MyCareerVerse 8 categories',
	'home.btn.howItWorks': 'How it works',
	'home.btn.getStarted': 'Get Started',
	'register.username.label': 'Username',
	'register.email.label': 'Email',
	'register.emailConfirm.label': 'Confirm Email',
	'register.password.label': 'Password',
	'register.passwordConfirm.label': 'Confirm Password',
	'register.moblile.label': 'Mobile',
	'register.creatAccount.btn': 'Create Account',
	'nav.link.home': 'Home',
	'nav.link.menu': 'Menu',
	'nav.link.settings': 'Settings',
	'nav.link.calculator': 'CAO Points Calculator',
	'nav.link.interest': 'Interest Profiler',
	'nav.link.dashboard': 'Student Dashboard',
	'nav.link.caoSim': 'CAO Simulator',
	'nav.link.support': 'Academic Support',
	'nav.link.notifications': 'Notification Centre',
	'nav.link.campusTours': 'Virtual Campus Tours',
	'nav.link.careerExp': '360 Career Experience',
	'nav.btn.logout': 'Logout',
	'footer.label': 'Ⓒ 2023 MyCareerVerse'
}