import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './styles/custom.scss'
import Header from './components/Header'
import Footer from './components/Footer'
import Register from './views/Register'
import Terms from './views/Terms'
import HomeScreen from './views/Home'
import ProtectedRoute from './routing/ProtectedRoute'
import Calculator from './views/Calculator'
import Contact from './views/Contact'
import InterestProfiler from './views/InterestProfiler'
import CAOSimulator from './views/CAOSimulator'
import Support from './views/Support'
import Notifications from './views/Notifications'
import CampusTours from './views/CampusTours'
import CareerExperience from './views/CareerExperience'
import Dashboard from './views/Dashboard'
import LoginModal from './features/auth/LoginModal'
import TermsModal from './features/auth/TermsModal'
import UpdatePassword from './views/UpdatePassword'
import ForgotPassword from './views/ForgotPassword'
import ResetCode from'./views/ResetCode'
import GetStartedModal from './features/home/GetStartedModal'
import HowItWorksModal from './features/home/HowItWorksModal'
import AboutUs from './views/AboutUs'
import ScrollToTop from './routing/ScrollToTop'
import EventsModal from './features/notifications/EventsModal'

function App() {
    return (
        <>
            <Router>
                <ScrollToTop />
                <Header />
                <div className="content">
                    <Routes>
                        <Route path='/' element={<HomeScreen />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/caopointscalculator' element={<Calculator />} />                        
                        <Route path='/register' element={<Register />} />
                        <Route path='/terms' element={<Terms />} />    
                        <Route path='/update-password' element={<UpdatePassword />} />                    
                        <Route path='/forgot-password' element={<ForgotPassword />} />  
                        <Route path='/reset-code' element={<ResetCode />} />                    
                        <Route element={<ProtectedRoute />}>
                            <Route path='/interest-profiler' element={<InterestProfiler />} />
                        </Route>                   
                        <Route element={<ProtectedRoute />}>
                            <Route path='/cao-simulator' element={<CAOSimulator />} />
                        </Route>
                        <Route element={<ProtectedRoute />}>
                            <Route path='/support' element={<Support />} />
                        </Route>
                        <Route element={<ProtectedRoute />}>
                            <Route path='/notifications' element={<Notifications />} />
                        </Route>
                        <Route element={<ProtectedRoute />}>
                            <Route path='/campus-tours' element={<CampusTours />} />
                        </Route>
                        <Route element={<ProtectedRoute />}>
                            <Route path='/career-experience' element={<CareerExperience />} />
                        </Route>       
                        <Route element={<ProtectedRoute />}>
                            <Route path='/dashboard' element={<Dashboard />} />
                        </Route>  
                        <Route path='/about' element={<AboutUs />} />                           
                    </Routes>
                </div>
                <Footer />                
            </Router>
            <LoginModal />
            <TermsModal />
            <GetStartedModal />
            <HowItWorksModal />
            <EventsModal />
        </>
    )
}
export default App