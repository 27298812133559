import { apiSlice } from "../../app/apiSlice";

export const simulatorAPI = apiSlice.injectEndpoints({    
    tagTypes: ['UserCourse'],
    endpoints: builder => ({
        getRankedUserCourses: builder.query({
            query: () => 'course/ranked',
            method: 'GET',
            providesTags: ['UserCourse']

        }),
        getCoursesList: builder.query({
            query: () => 'course/list',
            method: 'GET'       
        }),
    })
})

export const { useGetRankedUserCoursesQuery, useGetCoursesListQuery } = simulatorAPI