import { Row, Col, Nav, Tab } from 'react-bootstrap'
import _ from 'lodash'
import { getTabsEnabled } from './dashboardSlice'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import CareerCard from './cards/CareerCard'
import CoursesTabContent from './CoursesTabContent'


const CareerTabs = props => {   
    const { career, career_index } = props
    const [currentTab, setCurrentTab] = useState('career');
    const tabsEnabled = useSelector(state => getTabsEnabled(state, career_index))
    const courseLevels = [
        {
            title: 'NFQ Level 8', 
            key: 'level8',   
            level: 8,           
        },
        {
            title: 'NFQ Level 7', 
            key: 'level7',   
            level: 7                 
        },
        {
            title: 'NFQ Level 6',
            key: 'level6',
            level: 6         
        },
        {
            title: 'NFQ Level 5',
            key: 'level5',
            level: 5 
        },
        {
            title: 'Level 6 Apprenticeships',
            key: 'level6a',
            is_apprenticeship: true,
            level: 6 
        },
        {
            title: 'International',
            key: 'international',
            is_international: true
        },
        {
            title: 'Direct',
            key: 'direct',
            is_direct: true           
        },
    ]  
    
    return (
        <>        
            <Tab.Container 
                activeKey={currentTab} 
                onSelect={tab => setCurrentTab(tab)}
            >
                <Row>   
                    <Nav variant="pills">
                        <Col>
                            <Nav.Item>
                                <Nav.Link eventKey="career">
                                    Career
                                </Nav.Link>
                            </Nav.Item>  
                        </Col> 
                            {
                                _.map(courseLevels, courseLevel => (
                                    <Col xs={12} sm={6} lg={3} key={courseLevel.key}>
                                        <Nav.Item>
                                            <Nav.Link 
                                                eventKey={courseLevel.key}
                                                disabled={_.indexOf(tabsEnabled, courseLevel.key) === -1}
                                            >
                                                {courseLevel.title}
                                            </Nav.Link>
                                        </Nav.Item>    
                                    </Col>
                                ))
                            }
                    </Nav>                                     
                </Row>
                <Row>
                    <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey="career" className="career">                                
                                <CareerCard career={career} />                              
                            </Tab.Pane>
                            {
                                _.map(courseLevels, courseLevel => (
                                    <Tab.Pane eventKey={courseLevel.key} key={courseLevel.key}>
                                        {
                                            false &&_.isEmpty(courseLevel.courses) ? 
                                                <div className="no-courses">There are no courses to display in your selected locations</div>
                                            :
                                                <CoursesTabContent 
                                                    career={_.assign({}, props.career, {career_index})} 
                                                    courseLevel={courseLevel}                                                    
                                                />
                                        }                                        
                                    </Tab.Pane>    
                                ))
                            }                                              
                        </Tab.Content>
                    </Col>                
                </Row>
            </Tab.Container>
        </>
    )
}

export default CareerTabs