import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
	examSeasons: []	
}

const updatePoints = (state, index) => {
	state.examSeasons =  _.map(state.examSeasons, es => {
		if(es.active || !index) {
			const season = _.assign({}, es, {
				resultSet: _.map(es.resultSet, (r, i) => {
					const bonusPoints = _.indexOf(['H1', 'H2', 'H3', 'H4', 'H5', 'H6'], _.get(r, ['grade', 'name'])) !== -1 && _.get(r, ['subject' ,'bonus_points']) ? 
						r.subject.bonus_points : 
						0					
					const points =  r.grade && !(_.startsWith(_.get(r, ['grade', 'name']), 'F') && !_.get(r, ['subject', 'foundation_points'])) ? 
						(_.get(r, ['grade', 'points']) + bonusPoints) : 
						0
					return !index || index === i ? _.assign({}, r, {points}) : r
				})
			})	
			let totalPoints = _.reduce(_.take(_.map(_.orderBy(_.filter(season.resultSet, r => r.points), ['points'], ['desc']), rs => rs.points), 6), (sum, n) => sum + n)
			if(totalPoints > 600) {
				totalPoints = 600
			}
			return _.assign({}, season, {totalPoints})	
		}else {
			return es
		}				
	})
}

const calculatorSlice = createSlice({
	name: 'calculator',
	initialState,
	reducers: {
		updateSubject: (state, action) => {		
			const { subject, index } = action.payload
			state.examSeasons =  _.map(state.examSeasons, es => {
				if(es.active) {
					return _.assign({}, es, {
						resultSet: _.map(es.resultSet, (r, i) => index === i ? _.assign({}, r, {subject}) : r )
					})
				}else {
					return es;
				}				
			})
			updatePoints(state, index)
		},
		updateLevel: (state, action) => {	
			const { level, index } = action.payload
			state.examSeasons =  _.map(state.examSeasons, es => {
				if(es.active) {
					return _.assign({}, es, {
						resultSet: _.map(es.resultSet, (r, i) => index === i ? _.assign({}, r, {level, grade: undefined}) : r )
					})
				}else {
					return es
				}				
			})
			updatePoints(state, index)
		},		
		updateGrade: (state, action) => {
			const { grade, index } = action.payload
			state.examSeasons =  _.map(state.examSeasons, es => {
				if(es.active) {
					return _.assign({}, es, {
						resultSet: _.map(es.resultSet, (r, i) => index === i ? _.assign({}, r, {grade}) : r )})
				}else {
					return es
				}				
			})
			updatePoints(state, index)
		},
		selectSeason: (state, action) => {	
			state.examSeasons = _.map(state.examSeasons, es => _.omit(es, ['active']))
			if(!action.payload) {
				return
			}
			const { season , mandatory } = action.payload
			const { id, name } = season		
			if(_.find(state.examSeasons, es => es.id === id)) {
				state.examSeasons = _.map(state.examSeasons, es => es.id === id ? _.assign({}, es, {active: true}) : es)		
			}else {
				const resultSet = _.find(state.examSeasons, es =>  ['active']) ? 
					_.map(_.get(_.find(state.examSeasons, es =>  ['active']), ['resultSet']), rs => _.cloneDeep(_.omit(rs, ['grade', 'id', 'points']))) :
					[..._.map(mandatory, m => {return {subject: m}}), ..._.map(_.range(8 - _.size(mandatory)), s => {return {}})]
				state.examSeasons = _.concat(state.examSeasons, [{
					id,
					name,
					active: true,
					resultSet					
				}])
			}

			updatePoints(state)
		},
		resetSeason: (state, action) => {
			const { mandatory } = action.payload;
			state.examSeasons =  _.map(state.examSeasons, es => {
				if(es.active) {
					return _.assign({}, _.omit(es, ['totalPoints']), {
						resultSet: [..._.map(mandatory, m => {return {subject: m}}), ..._.map(_.range(8 - _.size(mandatory)), s => {return {}})],
						totalPoints: undefined
					})
				}else {
					return es
				}				
			})
		},
		setExamResult: (state, action) => {
			const { exam_result, form_index } = action.payload
			state.examSeasons =  _.map(state.examSeasons, es => {
				if(es.id === _.get(exam_result, ['season', 'id'])) {					
					return _.assign({}, _.omit(es, ['totalPoints']), {
						resultSet: _.map(es.resultSet, (r, index) => index === form_index ? exam_result : r),
						totalPoints: undefined
					})
				}else {
					return es
				}				
			})
			updatePoints(state, form_index)
			state.updated = true
		},
		setExamResults: (state, action) => {
			if(state.updated) {
				delete state.updated
			}
			else {
				const exam_results = action.payload
				const examSeasons = _.map(_.groupBy(exam_results, er => _.get(er, ['season', 'id'])), (es, id) => {
					return {
						id: Number(id),
						name: _.get(_.head(es), ['season', 'name']),
						resultSet : [
							..._.map(es, s => _.omit(s, ['season'])), 
							..._.map(_.range(8 - _.size(es)), s => {return {}})
						]				
					}
				})	
				state.examSeasons = examSeasons		
				updatePoints(state)
			}
		}
	}	
})

export const { 
	updateSubject,
	updateLevel,
	updateGrade,
	selectSeason,
	resetSeason,
	setExamResult,
	setExamResults
} = calculatorSlice.actions
export const getExamSeasons = state => _.get(state.calculatorSlice, ['examSeasons'])
export const getSubjects = state => _.get(state.calculatorSlice, ['subjects'])
export const getLevels = state => _.get(state.calculatorSlice, ['levels'])
export const getGrades = state => _.get(state.calculatorSlice, ['grades'])
export const getSelectedSeason = state => _.find(_.get(state.calculatorSlice, ['examSeasons']), 'active')
export const getLowestGrade = state =>  {
	return _.head(_.orderBy(_.map(state.calculatorSlice.examSeasons, (s, sIndex) => {		
		return _.assign({}, _.head(_.orderBy(s.resultSet, ['points'])), {
			seasonIndex: sIndex,
			seasonPoints: s.totalPoints
		})
	}), ['points']))
}
export const isResultsLoading = state => state.calculatorSlice.loadingExamResults
export default calculatorSlice.reducer