const InvestigativeIcon = props => {    
    return (           
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_930_12739)">
				<path d="M52.4722 49.7901L39.5988 36.4011C42.9088 32.4663 44.7223 27.5156 44.7223 22.3617C44.7223 10.32 34.9253 0.522949 22.8836 0.522949C10.842 0.522949 1.04492 10.32 1.04492 22.3617C1.04492 34.4033 10.842 44.2004 22.8836 44.2004C27.4042 44.2004 31.7122 42.8369 35.3953 40.2485L48.3665 53.7391C48.9087 54.3022 49.6379 54.6127 50.4194 54.6127C51.159 54.6127 51.8607 54.3307 52.3934 53.8179C53.5252 52.7288 53.5613 50.9229 52.4722 49.7901ZM22.8836 6.22C31.7843 6.22 39.0253 13.461 39.0253 22.3617C39.0253 31.2623 31.7843 38.5033 22.8836 38.5033C13.9829 38.5033 6.74197 31.2623 6.74197 22.3617C6.74197 13.461 13.9829 6.22 22.8836 6.22Z" fill="black"/>
			</g>
			<defs>
				<clipPath id="clip0_930_12739">
					<rect width="54.0897" height="54.0897" fill="white" transform="translate(0.110352 0.523438)"/>
				</clipPath>
			</defs>
		</svg>    
    )
}

export default InvestigativeIcon