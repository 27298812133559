import { useSelector, useDispatch } from 'react-redux'
import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import FormDecorator from '../components/FormDecorator'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { getResetCode, setResetCode } from '../features/auth/authSlice'


const ResetCode = () => {   
    const resetCode = useSelector(getResetCode)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const instructions = "Enter your email to recieve an email to reset your password:"
    
    const submitForm = e => {    
    	e.preventDefault()         
        navigate('/update-password')
    }


    return (
        <Container className="muvr-content">
            <Row className="top-row">
                <Col lg={{ span: 10, offset: 1 }} className="centered-content">
                    <div className="form-holder backdrop decorator">                      
                        <Form className="muvr-form" onSubmit={submitForm}>
                            <div className="muvr-form-body">
	                            <div className = "form-title">   
	                                Forgotten Password
	                            </div>   
                                {instructions} 
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Code:</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        value={resetCode}
                                        onChange={e => dispatch(setResetCode(_.get(e, ['target', 'value'])))}
                                    />                            
                                </Form.Group>
	                            
                            </div>                            
                            <div className="form-actions">
                                <Button variant="secondary" type="submit" size="lg">
                                    Enter Code
                                </Button>
                            </div>
                        </Form>      
                        <FormDecorator />
                    </div>                        
                </Col>                
            </Row>
        </Container>
    )
}

export default ResetCode