import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import moment from 'moment'


const initialState = {
	activeTab: 'month',
	activeStartDate: moment(new Date()).format()
}

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		setActiveTab: (state, action) => {
			if(action.payload === "week" || action.payload === "month"){				
				state.activeTab = action.payload				
			}
		},
		setActiveStartDate: (state, action) => {
			state.activeStartDate = action.payload
		}	
	}
})

export const { 
	setActiveTab, 	
	setActiveStartDate
} = notificationSlice.actions
export const getActiveTab = state => _.get(state.notificationSlice, ['activeTab'])
export const getActiveStartDate = state => _.get(state.notificationSlice, ['activeStartDate'])
export default notificationSlice.reducer