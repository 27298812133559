import { apiSlice } from "../../app/apiSlice";

export const courseAPI = apiSlice.injectEndpoints({    
    endpoints: builder => ({      
        getCourses: builder.query({
            query: () => 'course', 
            method: 'GET'      
        }),        
    })
})

export const  {     
    useGetCoursesQuery,      
} = courseAPI