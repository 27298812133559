import { apiSlice } from "../../app/apiSlice";

export const interestProfilerAPI = apiSlice.injectEndpoints({      
    endpoints: builder => ({      
        getQuestions: builder.query({
            query: () => 'profiler_question', 
            method: 'GET'      
        }),
        getInterestProfile: builder.query({
            query: () => 'interest_profile',
            method: 'GET'       
        }),
        updateInterestProfile: builder.mutation({
            query: profile => ({
                url: 'interest_profile',
                method: 'POST',              
                body: profile,           
            })            
        }),
        getCareers: builder.query({
            query: () => 'career',
            method: 'GET'       
        })                    
    })
})

export const  {     
    useGetQuestionsQuery,   
    useUpdateInterestProfileMutation,
    useGetInterestProfileQuery,    
    useGetCareersQuery
} = interestProfilerAPI