import { Container, Row, Col, Card } from 'react-bootstrap'
import image from '../assets/img/Group 38195.png'
import GrowthMindset from '../assets/pdf/GrowthMindset.pdf';    
import ExamTechniques from '../assets/pdf/ExamTechniques.pdf';    
import ImproveAttention from '../assets/pdf/ImproveAttention.pdf';    
import Memory from '../assets/pdf/Memory.pdf';    
import Motivation from '../assets/pdf/Motivation.pdf';    
import StayingHealthyForExams from '../assets/pdf/StayingHealthyForExams.pdf';    
import StudyTechniques from '../assets/pdf/StudyTechniques.pdf';    
import TimeAndOrganisationTips from '../assets/pdf/TimeAndOrganisationTips.pdf';    
import _ from 'lodash'
import { useSetStepsMutation } from '../features/user/userAPI' 
import { getCurrentUser, updateStepsCompleted } from '../features/auth/authSlice'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const Support = () => {
    const colors = ["#AFDBF3", "#CCCBF0", "#FAE4CD", "#C1F0BC", "#FFD3DB", "#B5EFDE", "#DBE2E2", "#AFDBF3"]   
    const pdfs = [
        {
            filename: 'GrowthMindset.pdf',
            file: GrowthMindset,
            label: 'Growth Mindset',
            image: require('../assets/img/pdfImages/growthmindset.jpg')
        },
        {
            filename: 'ExamTechniques.pdf',
            file: ExamTechniques,
            label: 'Exam Techniques',
            image: require('../assets/img/pdfImages/examtech.jpg')
        },
        {
            filename: 'ImproveAttention.pdf',
            file: ImproveAttention,
            label: 'Improve Attention',
            image: require('../assets/img/pdfImages/attention.jpg')
        },
        {
            filename: 'Memory.pdf',
            file: Memory,
            label: 'Improving Memory',
            image: require('../assets/img/pdfImages/memory.jpg')
        },
        {
            filename: 'Motivation.pdf',
            file: Motivation,
            label: 'Staying Motivated',
            image: require('../assets/img/pdfImages/motivation.jpg')
        },
        {
            filename: 'StayingHealthyForExams.pdf',
            file: StayingHealthyForExams,
            label: 'Lifestyle For Exams',
            image: require('../assets/img/pdfImages/healthy.jpg')
        },
        {
            filename: 'StudyTechniques.pdf',
            file: StudyTechniques,
            label: 'Study Techniques',
            image: require('../assets/img/pdfImages/studytech.jpg')
        },
        {
            filename: 'TimeAndOrganisationTips.pdf',
            file: TimeAndOrganisationTips,
            label: 'Getting Organised',
            image: require('../assets/img/pdfImages/timeandorganisation.jpg')
        }
    ]

    const user = useSelector(getCurrentUser)
    const [ setStepsCompleted ] = useSetStepsMutation(); 
    const dispatch = useDispatch()

    useEffect(() => {    
        if(user) {
            const bitSet = _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[6] 
            if(!bitSet || bitSet === '0') {
                setStepsCompleted({step_completed: 6})
                .then(result => dispatch(updateStepsCompleted({steps_completed: _.get(result, ['data', 'steps_completed'])})))
                .catch(error => console.log(error))
            }
        }
    }, [user, dispatch, setStepsCompleted]);


    return (       
        <Container fluid className="muvr-content support">
            <Row className="muvr-row top-row">
                <Col xs={{offset: 1, span: 11}}>
                    <div className="title">Tools To Reach Your Potential</div>           
                </Col>
            </Row>
            <Row className="caption-row">
                <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 1}} className="caption-col">
                    <div className="caption">                            
                        <div className="caption-text">
                            Access study guides, time management tips, and other resources to enhance your academic performance. Receive personalised support based on your CAO Points Calculator results
                        </div>
                    </div>                    
                </Col>
                <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 1}}>    
                    <div className="img-holder">                
                        <img src={image} alt="virtual campus tours"/>      
                    </div>              
                </Col>
            </Row>          
            <Row className="muvr-row">
                <Col xs={12} sm={{span: 10, offset: 1}} md={{span: 8, offset: 2}} className="centered-content">
                    <div className="title gradient-text">Helpful Pdfs</div>
                    <div className="sub-title">Here you will find helpful pdfs to download and view</div>
                    <Container className="help-pdfs">     
                        <Row>                        
                            {
                                pdfs.map((pdf, index) => (
                                    <Col key={index} className="centered-content">
                                        <Card className="pdf-card" key={pdf.filename} style={{backgroundColor: colors[index]}}>
                                            <Card.Header>
                                                {pdf.label}    
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Img src={pdf.image}/>    
                                            </Card.Body>
                                            <Card.Footer>
                                                <a href={pdf.file} download>View</a> 
                                            </Card.Footer>                                
                                        </Card>
                                    </Col>
                                ))   
                            }   
                        </Row>                     
                    </Container>
                </Col>
            </Row>
            <Row className="muvr-row">
                <Col className="centered-content">
                    <div className="title gradient-text">Helpful Videos</div>
                    <div className="sub-title">Here you will find helpful videos to view from different perspectives</div>
                    
                </Col>
            </Row>            
            <Row className="vids-row">   
                 <Col xs={12} className="centered-content">
                    <div className="vids-title gradient-text">
                        Academic Videos
                    </div>
                </Col>       
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            width="420" 
                            height="280"
                            src="https://www.youtube.com/embed/WbDpYMp8F6o" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                        />  
                    </div>
                    <div className="vid-text">
                        Interleaved Learning (Brain Hack) 
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            width="420" 
                            height="280"
                            src="https://www.youtube.com/embed/TEq5chOb4is" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                        />                       
                    </div>
                    <div className="vid-text">
                        Learn Anything FASTER With ChatGPT (13 ChatGPT Prompts For Studying)                   
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            width="420" 
                            height="280" 
                            src="https://www.youtube.com/embed/u5Y4pIsXTV0" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            aallow="fullscreen"
                            allowFullScreen
                        />
                    </div>
                    <div className="vid-text">
                        How to Mind Map with Tony Buzan - YouTube                   
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            width="420" 
                            height="280"
                            src="https://www.youtube.com/embed/pRsutB2NhLk" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                        />
                    </div>
                    <div className="vid-text">
                        How to study math | Jo Boaler and Lex Fridman                   
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            width="420" 
                            height="280"
                            src="https://www.youtube.com/embed/z-GtGH57J9I" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                        />  
                    </div>
                    <div className="vid-text">
                        How to remember everything you read                   
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            width="420" 
                            height="280"
                            src="https://www.youtube.com/embed/dTQDaUQ9MAU" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                        />
                    </div>
                    <div className="vid-text">
                        Brain Training to Beat Procrastination with the World's Easiest Learning Technique                   
                    </div>
                </Col>
                <Col className="centered-content">
                    <div className="help-vid">
                        <iframe 
                            width="420" 
                            height="280"
                            src="https://www.youtube.com/embed/Evbwtef8_-g" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="fullscreen"
                            allowFullScreen
                        />
                    </div>
                    <div className="vid-text">
                        Mind hack: 7 secrets to learn any new language | Steve Kaufmann | Big Think - YouTube                   
                    </div>
                </Col>
            </Row>            
        </Container>  
    )
}

export default Support