import { configureStore } from '@reduxjs/toolkit'
import appReducer from './appSlice'
import calculatorReducer from '../features/calculator/calculatorSlice'
import { calculatorAPI } from '../features/calculator/calculatorAPI'
import { apiSlice } from "./apiSlice"
import authReducer from '../features/auth/authSlice'
import { interestProfilerAPI } from '../features/interestProfiler/interestProfilerAPI'
import interestProfilerReducer from '../features/interestProfiler/interestProfilerSlice'
import courseReducer from '../features/course/courseSlice'
import { courseAPI } from '../features/course/courseAPI'
import simulatorReducer from '../features/simulator/simulatorSlice'
import { simulatorAPI } from '../features/simulator/simulatorAPI'
import dashboardReducer from '../features/dashboard/dashboardSlice'
import { dashboardAPI } from '../features/dashboard/dashboardAPI'
import { eventAPI } from '../features/notifications/eventAPI'
import  notificationReducer from '../features/notifications/notificationSlice'
import { userAPI } from '../features/user/userAPI'
import { careerTourAPI } from '../features/careerTour/careerTourAPI'
import careerTourReducer from '../features/careerTour/careerTourSlice'
import { contactAPI } from '../features/contact/contactAPI'
import _ from 'lodash'


const store = configureStore({
	reducer: {
		appSlice: appReducer,
		[apiSlice.reducerPath]: apiSlice.reducer,
		authSlice: authReducer,
		calculatorSlice: calculatorReducer,
		[calculatorAPI.reducerPath]: calculatorAPI.reducer,
		[userAPI.reducerPath]: userAPI.reducer,
		interestProfilerSlice: interestProfilerReducer,		
		[interestProfilerAPI.reducerPath]: interestProfilerAPI.reducer,	
    courseSlice: courseReducer,		
		[courseAPI.reducerPath]: courseAPI.reducer,
		simulatorSlice: simulatorReducer,		
		[simulatorAPI.reducerPath]: simulatorAPI.reducer,
		dashboardSlice: dashboardReducer,		
		[dashboardAPI.reducerPath]: dashboardAPI.reducer,
		[eventAPI.reducerPath]: eventAPI.reducer,
		notificationSlice: notificationReducer,
		careerTourSlice: careerTourReducer,
		[careerTourAPI.reducerPath]: careerTourAPI.reducer,
		[contactAPI.reducerPath]: contactAPI.reducer,
	},
	middleware: getDefaultMiddleware => 
		_.concat(
    		getDefaultMiddleware({
			    immutableCheck: { warnAfter: 50 },
			    serializableCheck: { warnAfter: 50 },
  			}),
			[
				calculatorAPI.middleware,
				interestProfilerAPI.middleware,
				courseAPI.middleware,
				simulatorAPI.middleware,
				dashboardAPI.middleware,
				eventAPI.middleware,
				userAPI.middleware,
				careerTourAPI.middleware,   
				contactAPI.middleware 
			]
    	),
})
export default store