import { Button, Modal, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getTranslation, isModalOpen, closeModal } from '../../app/appSlice'
import _ from 'lodash'
import { useState } from 'react'
import { CircleX } from 'akar-icons';  
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authAPI'    


function LoginModal() {

	const modalOpen = useSelector(isModalOpen('login'))
	const dispatch = useDispatch()
	const loginLabel = useSelector(getTranslation('login'))
	const usernamePlaceholder = useSelector(getTranslation('login.username.placeholder'))
	const passwordLabel = useSelector(getTranslation('login.password'))
	const passwordPlaceholder = useSelector(getTranslation('login.password.placeholder'))
	const registerLabel = useSelector(getTranslation('login.register'))
	const forgotPasswordLabel = useSelector(getTranslation('login.forgotPassword'))
	const [form, updateForm] = useState({email: '', password: ''}); 
	const [login, isLoading] = useLoginMutation()	  
	const [loginError, setLoginError] = useState(false)
	const [badEmail, setBadEmail] = useState(false)
	
	const handleSubmit = async (e) => {   		
		if(!_.get(isLoading, ['isLoading'])) {
			if(!/\S+@\S+\.\S+/.test(form.email)) {
				setBadEmail(true)
			}
			else {
				setBadEmail(false)
		        try {
		            const userData = await login(form).unwrap()
		            dispatch(setCredentials(userData))   
		            updateForm({email: '', password: ''})   
		            setLoginError(false)
		            setBadEmail(false)      
		            dispatch(closeModal())
		        } 
		        catch (err) {
		        	if(err.status === 401 && _.get(err, ['data', 'error']) === 'Unauthorized'){
		        		setLoginError("Login Failed")			        		
		        	}
		        }
			}
		}
		updateForm(_.assign({}, form, {'password': ''}))
	}

	return (
		<Modal 
			className="login-modal" 
			show={modalOpen} 
			onHide={() => dispatch(closeModal())}
			centered	
			size="lg"		
		>
			<Modal.Header>
				<div className="close-btn-holder">
					<button 
						className='btn btn-close' 
						onClick={() => dispatch(closeModal())}						
					>
						<CircleX size="50" strokeWidth="1"/>
					</button>
				</div>
				<Modal.Title>{_.capitalize(loginLabel)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>Email</Form.Label>
						<Form.Control 
							type="email" 
							placeholder={usernamePlaceholder} 
							value={form.username} 
							onChange={e => updateForm(_.assign({}, form, { email: _.get(e, ['target', 'value']) }))}
							size="lg"
							isInvalid={badEmail}
						/>						
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Label>{passwordLabel}</Form.Label>
						<Form.Control 
							type="password" 
							placeholder={passwordPlaceholder} 
							value={form.password} 
							onChange={e => updateForm(_.assign({}, form, { password: _.get(e, ['target', 'value']) }))}
							size="lg"
							isInvalid={loginError}
						/>
					</Form.Group>	
					{
						loginError && 
							<div className="login-error">
								Login Failed
							</div>			
					}	
				</Form>
				<a href="/register">{registerLabel}</a>
				<a href="/forgot-password">{forgotPasswordLabel}</a>	
			</Modal.Body>
			<Modal.Footer>				
				<Button variant="info" onClick={handleSubmit} disabled={form.password === ''}>
					{loginLabel}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default LoginModal