import { apiSlice } from "../../app/apiSlice";

export const dashboardAPI = apiSlice.injectEndpoints({   
    tagTypes: ['UserCareer', 'CareerCourses'],
    endpoints: builder => ({
        getSuggestedCourses: builder.query({
            query: () => 'course/suggested', 
            method: 'GET'      
        }),
        getUserCourses: builder.query({
            query: () => 'course/user',
            method: 'GET'       
        }),
        getPredictedPoints: builder.query({
            query: () => 'exam_result/predicted',
            method: 'GET'       
        }),
        getPathways: builder.query({
            query: () => 'career/pathways',
            method: 'GET'       
        }),       
        getCourses: builder.query({
            query: req  =>  {      
                const  { 
                    career_id, 
                    level, 
                    is_apprenticeship, 
                    is_international, 
                    is_direct,
                    page,
                    per_page,
                    locations
                } = req     
                return {    
                    url: 'course',
                    method: 'GET',
                    params: { 
                        career_id, 
                        level, 
                        is_apprenticeship, 
                        is_international, 
                        is_direct,
                        page,
                        per_page,
                        locations
                    }                                         
                } 
            },            
        })         
    })
})

export const  { 
    useGetSuggestedCoursesQuery,
    useGetUserCoursesQuery,
    useUpdateUserCoursesMutation,    
    useGetPredictedPointsQuery,
    useGetPathwaysQuery,
    useGetCareerCoursesQuery,
    useGetCoursesQuery
} = dashboardAPI