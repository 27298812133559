import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslation, openModal } from '../app/appSlice'
import { Container, Row, Col, Button } from 'react-bootstrap'
import logo from '../assets/img/MetaUnivrLogo.png'
import wheel from '../assets/img/wheel.svg'
import { getCurrentUser } from '../features/auth/authSlice'
import _ from 'lodash'
import classNames from 'classnames'
import { AiOutlineCheck } from 'react-icons/ai' 

const HomeScreen = () => {   
    const captionTitle = useSelector(getTranslation('home.caption.title'))
    const captionText = useSelector(getTranslation('home.caption.text'))
    const categoriesLabel = useSelector(getTranslation('home.categories.label'))
    const getStartedBtnLabel = useSelector(getTranslation('home.btn.getStarted'))
    const howItWorksBtnLabel = useSelector(getTranslation('home.btn.howItWorks'))
    const dispatch = useDispatch()
    const user = useSelector(getCurrentUser)
    const steps = ['calculator', 'profiler', 'dashboard', 'career-exp', 'campus-exp', 'simulator', 'support', 'notification']

    return (
        <Container className="muvr-content">
            <Row className="top-row muvr-row">
                <Col xs={12} sm={{span: 10, offset: 1}} lg={{span: 6, offset: 3}} className="caption-col">
                    <div className="caption">
                        <div className="caption-title">{captionTitle}</div>
                        <div className="caption-text">{captionText}</div>
                        <div className="caption-text">
                            Our platform strives to provide comprehensive information, interactive experiences, and personalised guidance at every step. We aim to empower students in their career exploration journey, ensuring informed decisions about their future
                        </div>
                    </div>
                    <Button 
                        variant="primary" 
                        size="lg" 
                        onClick={() => dispatch(openModal({modalName: 'get-started'}))}
                    >
                        {getStartedBtnLabel}
                    </Button>
                    <Button 
                        variant="outline-primary" 
                        size="lg" 
                        onClick={() => dispatch(openModal({modalName: 'how-it-works'}))}
                    >
                        {howItWorksBtnLabel}
                    </Button>
                </Col>              
            </Row>
            <Row className="muvr-row">
                <Col className='centered-content test'>
                    <div className="category-title">{categoriesLabel}</div>     
                    <div className="menu-holder">    
                        <div className="outer-ring">
                            <ul className='circle-container'>
                                {
                                    steps.map((step, index) => (
                                        <li key={step} className={classNames({'complete': _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[index] === "1"})}>
                                            {
                                                _.reverse(parseInt(_.get(user, ['steps_completed'])).toString(2).split(''))[index] === "1" && <AiOutlineCheck />
                                            }
                                        </li>
                                    ))
                                }  
                            </ul>  
                        </div>
                        <div className="wheel-holder">    
                            <div className="wheel-menu">                       
                                <Link to="/caopointscalculator">
                                    <div className="step calculator"></div>
                                </Link>
                                {
                                    user ?
                                        <>
                                            <Link to="/interest-profiler">
                                                <div className="step active profiler"></div>
                                            </Link>                  
                                            <Link to="/dashboard">
                                                <div className="step active dashboard"></div>
                                            </Link>
                                            <Link to="/career-experience">
                                                <div className="step active career-exp"></div>
                                            </Link>
                                            <Link to="/campus-tours">
                                                <div className="step active campus-exp"></div>
                                            </Link>
                                            <Link to="/cao-simulator">
                                                <div className="step active simulator"></div>
                                            </Link>
                                            <Link to="/support">
                                                <div className="step active support"></div>
                                            </Link>
                                            <Link to="/notifications">
                                                <div className="step active notification"></div>
                                            </Link>
                                        </>
                                    :
                                        _.map(_.tail(steps), step => (
                                            <div 
                                                className={`step ${step}`}
                                                onClick={() => dispatch(openModal({modalName: 'login'}))}
                                                key={step}
                                            >    
                                            </div>
                                        ))                              
                                    }
                                <div className="wheel-overlay"><img src={wheel} alt="wheel-overlay"/></div>          
                                <div className="logo-holder">                           
                                    <img className="logo" src={logo} alt="metaunivr-logo"/>
                                </div>                                             
                            </div>                                
                        </div>  
                    </div>                  
                </Col>
            </Row>  
        </Container>
    )
}

export default HomeScreen