import { apiSlice } from "../../app/apiSlice";

export const careerTourAPI = apiSlice.injectEndpoints({    
    endpoints: builder => ({
        getCareerTours: builder.query({
            query: () => 'institution/tour',
            method: 'GET'       
        }),       
    })
})

export const { useGetCareerToursQuery } = careerTourAPI