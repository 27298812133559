import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { unsetCredentials } from '../features/auth/authSlice'
import { Navbar, Nav, NavDropdown, Container, Button, Dropdown, Row, Col } from 'react-bootstrap'
import logo from '../assets/img/MetaUnivrLogo.png'
import { setTheme, getTheme, getTranslation, openModal } from '../app/appSlice'
import { Bell } from 'akar-icons'
import { getCurrentUser } from '../features/auth/authSlice'
import { useLogoutMutation } from '../features/auth/authAPI'
import _ from 'lodash'
import { AiOutlineUser } from "react-icons/ai";
import Switch from "react-switch";

const Header = () => {
    const dispatch = useDispatch()
    const theme = useSelector(getTheme)
    const homeLabel = useSelector(getTranslation('nav.link.home'))
    const calculatorLabel = useSelector(getTranslation('nav.link.calculator'))
    const menuLabel = useSelector(getTranslation('nav.link.menu'))
    const interestLabel = useSelector(getTranslation('nav.link.interest'))
    const dashboardLabel = useSelector(getTranslation('nav.link.dashboard'))
    const caoSimLabel = useSelector(getTranslation('nav.link.caoSim'))
    const supportLabel = useSelector(getTranslation('nav.link.support'))
    const notificationsLabel = useSelector(getTranslation('nav.link.notifications'))
    const courseExpLabel = useSelector(getTranslation('nav.link.campusTours'))
    const careerExpLabel = useSelector(getTranslation('nav.link.careerExp'))
    const logoutBtnLabel = useSelector(getTranslation('nav.btn.logout'))
    const loginBtnLabel = useSelector(getTranslation('login.btn'))
    const user = useSelector(getCurrentUser)
    const navigate = useNavigate()
    const [ logout ] = useLogoutMutation()

    const CloseBtnIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="#90D9C6" height="20" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"/>
        </svg>      
    )

    const HandleIcon = () => (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.10713 3.15491V2.39435M9.10713 17.6056V16.8451M15.8942 9.99998H16.6483M1.56592 9.99998H2.32004M14.0089 5.05632L15.1401 3.91548M3.07416 16.0845L4.20534 14.9436M3.07416 3.91548L4.20534 5.05632M14.0089 14.9436L15.1401 16.0845" stroke="#1C1C25" strokeWidth="3" strokeLinecap="round"/>
            <path d="M9.10706 13.0422C10.773 13.0422 12.1235 11.6801 12.1235 9.99996C12.1235 8.31977 10.773 6.9577 9.10706 6.9577C7.4411 6.9577 6.09058 8.31977 6.09058 9.99996C6.09058 11.6801 7.4411 13.0422 9.10706 13.0422Z" stroke="#1C1C25" strokeWidth="3" strokeLinecap="round"/>
        </svg>
)

    const handleLogout = () => {      
        logout()
            .then(result => {
                if(_.get(result, ['data', 'success'])) {
                    dispatch(unsetCredentials())
                    navigate("/")
                }                
            })
            .catch(error => console.error('Logout Error', error))
    }


    return (
        <div className="header">
            <Navbar variant={theme}>
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img
                            src={logo}                        
                            className="d-inline-block align-top"
                            alt="MetaUniVR logo"
                        />
                    </Navbar.Brand>                 
                    <Nav className="me-auto d-none d-xl-flex nav-lg">
                        <Container>
                            <Row>
                                <Col>
                                    <Nav.Link as={Link} to="/">{homeLabel}</Nav.Link>             
                                </Col>
                                <Col>
                                    <Nav.Link as={Link} to="/caopointscalculator">{calculatorLabel}</Nav.Link>  
                                </Col>
                                <Col>
                                    <Nav.Link as={Link} to="/interest-profiler" disabled={!user}>{interestLabel}</Nav.Link>
                                </Col>
                            </Row>
                            <Row>                                
                                <Col>
                                    <Nav.Link as={Link} to="/dashboard" disabled={!user}>{dashboardLabel}</Nav.Link> 
                                </Col>
                           
                                <Col>
                                    <Nav.Link as={Link} to="/cao-simulator" disabled={!user}>{caoSimLabel}</Nav.Link>
                                </Col>
                                <Col>
                                    <Nav.Link as={Link} to="/support" disabled={!user}>{supportLabel}</Nav.Link>
                                </Col>                                
                            </Row>
                            <Row>
                                <Col>
                                    <Nav.Link as={Link} to="/notifications" disabled={!user}>{notificationsLabel}</Nav.Link>
                                </Col>
                                <Col>
                                    <Nav.Link as={Link} to="/campus-tours" disabled={!user}>{courseExpLabel}</Nav.Link>
                                </Col>
                            
                                <Col>
                                    <Nav.Link as={Link} to="/career-experience" disabled={!user}>{careerExpLabel}</Nav.Link>
                                </Col>                                                            
                            </Row>
                        </Container> 
                    </Nav>               
                    <div className="nav-group">        
                        <div className="top-row">                         
                            {
                                user ?
                                    <>   
                                        <div className="event-cal">             
                                            <Button 
                                                variant="primary" 
                                                className="icon-only bell-btn"                                         
                                                as={Link} to="/notifications"
                                            >
                                                <Bell strokeWidth={1} size={30}/>
                                            </Button>                                          
                                        </div>
                                        <AiOutlineUser className="user-icon"/>
                                        <NavDropdown title={user.username} menuVariant="dark" drop="down" className="user-menu">
                                            <NavDropdown.Item onClick={handleLogout}>{logoutBtnLabel}</NavDropdown.Item>
                                        </NavDropdown>                                      
                                    </>                                       
                                :
                                    <Button onClick={() => dispatch(openModal({modalName: 'login'}))}>{loginBtnLabel}</Button>
                            }  
                        </div>
                        <label className="theme-switch">                          
                            <Switch 
                                onChange={checked => dispatch(setTheme(checked ? 'light' : 'dark'))} 
                                checked={theme === 'light'} 
                                checkedIcon={false}
                                uncheckedIcon={false}
                                checkedHandleIcon={<HandleIcon />}
                                uncheckedHandleIcon={<HandleIcon />}
                                onColor="#90DAC4"
                                offColor="#90DAC4"
                            />
                        </label>                 
                    </div>
                </Container>       
            </Navbar>
            <Nav className="d-xs-flex d-xl-none sub-nav">
                <Nav.Link as={Link} to="/">{homeLabel}</Nav.Link>                        
                <Nav.Link as={Link} to="/caopointscalculator">{calculatorLabel}</Nav.Link>
                <NavDropdown drop="down" title={menuLabel} id="nav-menu" color="primary" align={{lg: 'start'}}>
                    <Dropdown.Toggle
                        aria-controls="toggle-collapse"
                        className="close-btn icon-only"                                
                        as="button"
                    >  
                        <CloseBtnIcon />                             
                    </Dropdown.Toggle>
                    <NavDropdown.Item className="menu-title" as={Link} to="/">{homeLabel}</NavDropdown.Item>    
                    <NavDropdown.Item as={Link} to="/caopointscalculator">{calculatorLabel}</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/interest-profiler" disabled={!user}>{interestLabel}</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/dashboard" disabled={!user}>{dashboardLabel}</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cao-simulator" disabled={!user}>{caoSimLabel}</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/support" disabled={!user}>{supportLabel}</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/notifications" disabled={!user}>{notificationsLabel}</NavDropdown.Item>
                    <NavDropdown.Item className="last-row" as={Link} to="/campus-tours" disabled={!user}>{courseExpLabel}</NavDropdown.Item>
                    <NavDropdown.Item className="last-row" as={Link} to="/career-experience" disabled={!user}>{careerExpLabel}</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </div>
    )
}

export default Header