const SocialIcon = props => {    
    return (           
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 298.141 298.141" width="55" height="55">
            <path d="M297.297,120.952l-6.179-31.012c-2.063-10.321-11.9-18.406-22.398-18.406h-13.36c-0.016,0-0.03,0.002-0.046,0.003
                c12.169-6.293,20.516-19.018,20.516-33.668C275.83,16.988,258.893,0,238.074,0c-20.822,0-37.763,16.988-37.763,37.869
                c0,14.65,8.348,27.375,20.52,33.668c-0.018,0-0.034-0.003-0.051-0.003h-13.362c-10.494,0-20.332,8.085-22.398,18.411l-1.441,7.236
                h-69.019l-1.443-7.242c-2.064-10.32-11.901-18.405-22.396-18.405H77.359c-0.017,0-0.032,0.003-0.049,0.003
                c12.17-6.293,20.518-19.018,20.518-33.668C97.828,16.988,80.89,0,60.07,0C39.251,0,22.312,16.988,22.312,37.869
                c0,14.65,8.347,27.375,20.518,33.668c-0.016-0.001-0.031-0.003-0.047-0.003H29.42c-10.494,0-20.331,8.085-22.396,18.41l-6.18,31.008
                c-0.438,2.203,0.133,4.486,1.557,6.223c1.425,1.736,3.553,2.743,5.799,2.743h37.139l54.743,119.324
                c-1.978,2.629-3.405,5.662-4.054,8.922l-6.181,31.011c-0.438,2.202,0.133,4.486,1.557,6.223c1.425,1.736,3.553,2.743,5.799,2.743
                h103.736c2.246,0,4.374-1.007,5.799-2.743c1.424-1.736,1.995-4.021,1.557-6.223l-6.18-31.008c-0.648-3.26-2.076-6.294-4.053-8.923
                l54.743-119.326h37.137c2.246,0,4.374-1.007,5.798-2.743C297.164,125.438,297.735,123.155,297.297,120.952z M238.074,15
                c12.548,0,22.756,10.259,22.756,22.869c0,12.606-10.208,22.862-22.756,22.862c-12.552,0-22.763-10.256-22.763-22.862
                C215.311,25.259,225.522,15,238.074,15z M238.074,75.731c4.169,0,8.178-0.691,11.932-1.948l-11.933,11.979L226.134,73.78
                C229.89,75.039,233.901,75.731,238.074,75.731z M60.07,15c12.549,0,22.758,10.259,22.758,22.869
                c0,12.606-10.209,22.862-22.758,22.862c-12.549,0-22.758-10.256-22.758-22.862C37.312,25.259,47.521,15,60.07,15z M60.07,75.731
                c4.171,0,8.182-0.692,11.936-1.95L60.07,85.763l-11.933-11.98C51.892,75.04,55.9,75.731,60.07,75.731z M21.733,92.881
                c0.664-3.321,4.328-6.347,7.687-6.347h10.248l15.089,15.148c1.407,1.413,3.319,2.207,5.313,2.207c1.994,0,3.906-0.794,5.314-2.207
                l15.091-15.148h10.248c3.358,0,7.022,3.025,7.686,6.342l4.393,22.042H17.342L21.733,92.881z M106.345,283.141l4.395-22.048
                c0.658-3.314,4.32-6.333,7.683-6.333h10.247l15.088,15.144c1.407,1.413,3.319,2.207,5.313,2.207c1.994,0,3.905-0.794,5.313-2.206
                l15.09-15.145h10.246c3.362,0,7.024,3.019,7.684,6.336l4.393,22.045H106.345z M149.07,228.957
                c-12.547,0-22.755-10.259-22.755-22.868c0-12.608,10.208-22.866,22.755-22.866c12.548,0,22.757,10.258,22.757,22.866
                C171.827,218.698,161.618,228.957,149.07,228.957z M149.07,243.957c4.17,0,8.181-0.691,11.935-1.949l-11.935,11.977l-11.932-11.977
                C140.892,243.266,144.9,243.957,149.07,243.957z M185.533,240.581c-1.883-0.534-3.837-0.821-5.814-0.821h-13.36
                c-0.017,0-0.032,0.002-0.049,0.002c12.171-6.293,20.518-19.02,20.518-33.673c0-20.88-16.938-37.866-37.757-37.866
                c-20.818,0-37.755,16.986-37.755,37.866c0,14.653,8.347,27.38,20.517,33.673c-0.016,0-0.032-0.002-0.048-0.002h-13.362
                c-1.977,0-3.931,0.287-5.813,0.821L61.84,129.918h50.103c2.246,0,4.374-1.007,5.799-2.743c1.424-1.736,1.995-4.02,1.557-6.223
                l-1.748-8.771h63.042l-1.747,8.771c-0.439,2.202,0.133,4.485,1.558,6.222c1.424,1.736,3.552,2.743,5.798,2.743h50.102
                L185.533,240.581z M195.342,114.918l4.39-22.037c0.664-3.321,4.328-6.347,7.688-6.347h10.248l15.095,15.149
                c1.407,1.412,3.319,2.206,5.313,2.206c1.994,0,3.905-0.795,5.313-2.207l15.088-15.148h10.245c3.36,0,7.024,3.024,7.688,6.342
                l4.392,22.042H195.342z"/>
        </svg>      
    )
}

export default SocialIcon 