import { apiSlice } from "../../app/apiSlice";
import { encode as base64_encode } from 'base-64';

export const authAPI = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: ({email, password}) => ({
                url: 'auth/tokens',
                method: 'POST',              
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64_encode(`${email}:${password}`)
                },           
            })
        }),
        register: builder.mutation({
            query: userData => ({
                url: 'user/register',
                method: 'POST',              
                body: userData    
            })
        }),
        resetPassword: builder.mutation({
            query: email => ({
                url: 'user/reset-password',
                method: 'POST',              
                body: {email}    
            })
        }),
        updatePassword: builder.mutation({
            query: req => ({
                url: 'user/update-password',
                method: 'POST',              
                body: req    
            })
        }),
        logout: builder.mutation({
            query: () => ({
                url: 'auth/tokens',
                method: 'DELETE'    
            })
        }),
    })
})

export const {
    useLoginMutation,
    useRegisterMutation,
    useLogoutMutation,
    useResetPasswordMutation,
    useUpdatePasswordMutation,
} = authAPI