import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import _ from 'lodash'
import { useSendMessageMutation } from '../features/contact/contactAPI'

const blankForm = {
    name: '',
    email: '', 
    subject: '',
    message: ''
}

const Contact = () => {
    const [form, updateForm] = useState(blankForm); 
    const [ sendMessage ] = useSendMessageMutation()

    const submitForm = e => {        
        e.preventDefault()       
        sendMessage(form)
        .then(result => {updateForm(blankForm)})       
        .catch(error => console.error('Update Error', error))
    }


    return (
        <Container className="muvr-content contact-us">
            <Row className="muvr-row top-row">
                <Col xs={{offset: 1, span: 10}}>
                    <div className="title">
                        <div className="main-title">Contact Us</div>
                        <div className="title-desc">Please send us a message or email us at info@mycarerverse.com</div>
                    </div>                   
            </Col>                
            </Row>     
            <Row>
                <Col>                  
                    <div className="form-holder">
                        <Form className="muvr-form" onSubmit={submitForm}>
                            <div >
                                <Form.Group className="mb-3" controlId="form-name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        value={form.name}
                                        onChange={e => updateForm(_.assign({}, form, { name: _.get(e, ['target', 'value']) }))}
                                    />                            
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="form-email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        type="email"
                                        value={form.email}
                                        onChange={e => updateForm(_.assign({}, form, { email: _.get(e, ['target', 'value']) }))}
                                    />                            
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="form-subject">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        value={form.subject}
                                        onChange={e => updateForm(_.assign({}, form, { subject: _.get(e, ['target', 'value']) }))}
                                    />                            
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="form-message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        value={form.message}
                                        onChange={e => updateForm(_.assign({}, form, { message: _.get(e, ['target', 'value']) }))}
                                        as="textarea" rows={4}
                                    />                            
                                </Form.Group>
                            </div>                            
                            <div className="form-actions">
                                <Button 
                                    variant="secondary" 
                                    type="submit" 
                                    size="sm"
                                    disabled={
                                        form['name'] === '' || 
                                        form['email'] ==='' || 
                                        form['subject'] ===''  || 
                                        form['message'] ===''
                                    }
                                >
                                    Send   
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact