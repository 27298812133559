import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
	courses: []
}

const interestProfilerSlice = createSlice({
	name: 'course',
	initialState,
	reducers: {
		setCourses: (state, action) => {
			state.courses = action.payload
		}
	}
})

export const getCourses = state => _.get(state.courseSlice, ['courses'])
export default interestProfilerSlice.reducer