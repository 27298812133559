import { Button, Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { isModalOpen, closeModal } from '../../app/appSlice'
import { CircleX } from 'akar-icons';  


function HowItWorksModal() {

    const modalOpen = useSelector(isModalOpen('how-it-works'))
	const dispatch = useDispatch()

	return (
		<Modal 
			show={modalOpen} 
			onHide={() => dispatch(closeModal())}
			centered	
			size="lg"		
		>
			<Modal.Header>
				<div className="close-btn-holder">
					<button 
						className='btn btn-close' 
						onClick={() => {dispatch(closeModal())}}
					>
						<CircleX size="50" strokeWidth="1"/>
					</button>
				</div>
				<Modal.Title className="hiw-title">
					How it works
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>              
                <ul className="no-lst">
                    <li><strong>Step 1: CAO Points Calculator: </strong>Use our CAO Points Calculator to assess your eligibility for specific courses based on your academic achievements and qualifications</li>
                    <li><strong>Step 2: Interest Profiler: </strong>Discover suitable career options by completing the John Holland Interest Profiler, which evaluates your personal preferences</li>
                    <li><strong>Step 3: Student Dashboard: </strong>Access your personalised dashboard to manage your career exploration journey</li>
                    <li><strong>Step 4: 360 Career Experience: </strong>Gain insights into various professions through interactive simulations in virtual reality. Experience the day-to-day activities and challenges of different careers</li>
                    <li><strong>Step 5: Virtual Campus Tours: </strong>Immerse yourself in virtual reality to explore college campuses and facilities, giving you a glimpse of the campus atmosphere</li>
                    <li><strong>Step 6: CAO Simulator: </strong>Familiarise yourself with the CAO application process through our interactive Mock CAO form. Gain hands-on experience before submitting the actual application</li>
                    <li><strong>Step 7: Academic Support: </strong>Access study guides, time management tips, and other resources to enhance your academic performance. Receive personalised support based on your CAO Points Calculator results</li>
                    <li><strong>Step 8: Notification Centre: </strong>Stay up to date with Open Days and important deadlines by opting for personalised notifications. Relax knowing you won't miss any crucial dates this year</li>                   
                </ul>			
			</Modal.Body>
			<Modal.Footer>				
				<Button variant="secondary" onClick={() => dispatch(closeModal())}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default HowItWorksModal
