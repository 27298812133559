const EnterprisingIcon = props => {    
    return (           
        <svg version="1.1" id="Capa_1" x="0px" y="0px" width="55" height="55" viewBox="0 0 443.09 443.09">
            <g>
                <g>
                    <path d="M438.726,394.792c-0.346-1.057-8.76-26.224-22.125-53.958c-20.216-41.941-40.915-67.544-63.26-78.268
                        c-0.053-0.024-40.718-18.881-40.718-18.881l-17.749-14.967l-28.081,27.926l17.3,124.597c0.06,0.39-0.039,0.788-0.271,1.105
                        l-17.726,24.259c-0.282,0.388-0.731,0.615-1.211,0.615s-0.93-0.229-1.211-0.615l-17.726-24.259
                        c-0.231-0.317-0.329-0.716-0.271-1.105L262.93,256.99l-28.417-28.271l-17.751,14.967c0,0-78.601,35.955-106.227,43.736
                        c4.165-16.975,10.416-38.6,17.245-58.98c5.455-16.284-3.316-33.913-19.604-39.368c-16.281-5.461-33.916,3.306-39.373,19.59
                        c-0.332,0.99-8.203,24.527-15.102,50.02c-13.109,48.467-13.9,68.803-3.226,83.695c6.483,9.055,16.556,14.271,27.615,14.316h0.224
                        c18.168,0,52.404-8.996,87.939-22.5l0.025,108.895h196.818l0.023-69.835c6.541,14.139,11.539,27.148,14.263,34.609
                        c-1.183,6.678-3.968,19.021-10.146,35.226h65.68c6.027-20.646,7.164-33.898,7.328-36.416
                        C440.517,402.652,439.999,398.616,438.726,394.792z"/>
                    <path d="M264.686,227.469c45.502,0,79.452-42.387,80.223-113.476c0.5-49.327-22.996-78.843-80.223-78.843
                        c-57.225,0-80.716,29.516-80.208,78.843C185.241,185.082,219.187,227.469,264.686,227.469z"/>
                    <path d="M58.809,110.044c4.413,6.466,8.98,13.145,9.26,22.195l0.385,17.677c0,2.64,2.137,4.781,4.768,4.781h2.918
                        c0.902,5.026,5.294,8.837,10.579,8.837c5.283,0,9.669-3.811,10.571-8.837h2.922c2.631,0,4.772-2.141,4.772-4.781l0.403-17.671
                        c0.278-9.035,4.838-15.727,9.251-22.197c5.275-7.751,10.746-15.77,10.748-29.903c0-20.261-17.345-36.741-38.668-36.741
                        c-21.325,0-38.67,16.479-38.67,36.741C48.048,94.271,53.519,102.289,58.809,110.044z M86.716,120.068l-5.931-33.74
                        c-0.136-1.018,0.428-1.807,0.681-2.103c0.475-0.551,1.105-0.897,1.615-0.897c0.648,0,1.147,0.481,1.387,0.774
                        c0.554,0.669,1.373,1.063,2.248,1.063c0.871,0,1.686-0.394,2.243-1.063c0.242-0.293,0.734-0.774,1.379-0.774
                        c0.569,0,1.212,0.335,1.684,0.884c0.3,0.343,0.761,1.076,0.623,2.112L86.716,120.068z M86.718,52.958
                        c16.057,0,29.111,12.198,29.111,27.188c0,11.185-4.21,17.362-9.093,24.535c-4.055,5.953-8.974,13.154-10.466,22.648l-4.913-0.001
                        l7.028-40.035c0.003-0.023,0.007-0.04,0.01-0.061c0.37-2.458-0.339-4.936-1.951-6.817c-1.566-1.826-3.846-2.912-6.106-2.912
                        c-1.27,0-2.51,0.341-3.622,0.98c-1.124-0.639-2.364-0.98-3.635-0.98c-2.239,0-4.437,1.063-6.028,2.918
                        c-1.646,1.913-2.383,4.396-2.024,6.803c0.006,0.021,0.01,0.044,0.013,0.068l7.024,40.03l-4.883-0.001
                        c-1.491-9.499-6.423-16.708-10.489-22.66c-4.874-7.165-9.096-13.342-9.096-24.517C57.598,65.156,70.664,52.958,86.718,52.958z"/>
                    <path d="M86.718,34.639c2.503,0,4.531-2.029,4.531-4.532L91.248,4.533C91.248,2.029,89.221,0,86.716,0
                        c-2.503,0-4.53,2.03-4.53,4.533v0.001v25.573c0,1.25,0.506,2.384,1.326,3.204C84.332,34.131,85.462,34.639,86.718,34.639z"/>
                    <path d="M122.106,47.52c1.294,0,2.577-0.55,3.474-1.619l16.438-19.592c1.61-1.917,1.36-4.777-0.558-6.386
                        c-1.918-1.608-4.78-1.357-6.385,0.56l-16.438,19.592c-1.61,1.917-1.359,4.776,0.559,6.385
                        C120.043,47.173,121.076,47.52,122.106,47.52z"/>
                    <path d="M136.475,76.393c0.389,2.199,2.301,3.746,4.458,3.746c0.262,0,0.526-0.022,0.793-0.07l25.185-4.443
                        c2.466-0.435,4.111-2.786,3.678-5.251c-0.436-2.465-2.791-4.109-5.252-3.676l-25.185,4.442
                        C137.686,71.576,136.039,73.927,136.475,76.393z"/>
                    <path d="M136.664,108.77c-2.172-1.253-4.941-0.508-6.191,1.659c-1.251,2.167-0.508,4.939,1.657,6.19l22.15,12.787
                        c0.716,0.412,1.494,0.608,2.262,0.608c1.565,0,3.091-0.812,3.931-2.268c1.25-2.167,0.508-4.939-1.66-6.191L136.664,108.77z"/>
                    <path d="M42.958,110.428c-1.251-2.168-4.022-2.911-6.191-1.659l-22.146,12.787c-2.168,1.252-2.91,4.024-1.659,6.192
                        c0.838,1.454,2.364,2.267,3.93,2.267c0.769,0,1.549-0.196,2.264-0.608l22.145-12.787
                        C43.466,115.368,44.211,112.595,42.958,110.428z"/>
                    <path d="M32.499,80.137c2.159,0,4.069-1.548,4.458-3.747c0.436-2.465-1.212-4.815-3.678-5.25L8.094,66.7
                        c-2.465-0.434-4.814,1.212-5.249,3.677c-0.434,2.465,1.211,4.816,3.679,5.251l25.182,4.439
                        C31.973,80.114,32.237,80.137,32.499,80.137z"/>
                    <path d="M47.853,45.9c0.896,1.067,2.18,1.619,3.474,1.619h0.003c1.028,0,2.062-0.348,2.911-1.06
                        c1.916-1.61,2.167-4.468,0.56-6.386l-16.44-19.591c-1.61-1.916-4.469-2.167-6.387-0.558c-0.959,0.805-1.5,1.922-1.602,3.079
                        c-0.103,1.155,0.239,2.349,1.042,3.308L47.853,45.9z"/>
                </g>
            </g>
        </svg>   
    )
}

export default EnterprisingIcon 