import decoratorImg from '../assets/img/books.png'

const FormDecorator = props => {
	return(
		<div className="form-decorator">	
			<svg className="upper-circle" width="126" height="126" xmlns="http://www.w3.org/2000/svg">
				<circle cx="63" cy="63" r="63" fill="#FAE4CD"/>
			</svg>
			<svg className="lower-circle" width="126" height="126"xmlns="http://www.w3.org/2000/svg">
				<circle cx="63" cy="63" r="63" fill="#FAE4CD"/>
			</svg>	
			<img className="books" src={decoratorImg} alt="metaUniVR form decorator"/>	
			<svg className="half-ring" width="168" height="122" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.165704 65.8237C2.94412 54.2968 8.1037 43.4788 15.3128 34.0651C22.5219 24.6515 31.621 16.8504 42.0252 11.1634C52.4294 5.47645 63.9084 2.02939 75.7243 1.04385C87.5403 0.0583075 99.4318 1.55609 110.634 5.44091C121.837 9.32572 132.103 15.5116 140.772 23.6009C149.441 31.6903 156.321 41.504 160.971 52.4113C165.62 63.3187 167.936 75.0782 167.769 86.934C167.603 98.7899 164.957 110.48 160.002 121.252L127.897 106.486C130.792 100.193 132.337 93.3633 132.435 86.4371C132.532 79.5109 131.179 72.6409 128.463 66.2688C125.747 59.8967 121.727 54.1634 116.662 49.4376C111.598 44.7118 105.601 41.098 99.0562 38.8284C92.5116 36.5589 85.5646 35.6839 78.6616 36.2597C71.7587 36.8354 65.0526 38.8492 58.9744 42.1716C52.8962 45.4939 47.5805 50.0513 43.3689 55.5509C39.1573 61.0504 36.1431 67.3703 34.5199 74.1043L0.165704 65.8237Z" fill="#AFDBF3"/>
			</svg>
			<svg  className="line" width="677" height="211" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 196.689C44.3016 194.353 79.7481 219.939 190.906 158.134C256.361 121.739 314.066 115.711 361.914 119.238C425.573 123.929 490.777 135.632 547.482 106.323C585.271 86.7906 628.254 55.6915 668 8" stroke="#CCCBF0" strokeWidth="22"/>
			</svg>
		</div>
	)
}

export default FormDecorator