import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {}

const careerTourSlice = createSlice({
	name: 'careerTour',
	initialState,
	reducers: {
		setInstitutions: (state, action) => {
			const { institutions } = action.payload
			state.institutions = institutions
		}
	}
})

export const {
	setInstitutions
} = careerTourSlice.actions
export const getInstitutions = state => _.get(state.careerTourSlice, ['institutions'])
export default careerTourSlice.reducer