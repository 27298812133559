import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import CalculatorComponent from '../features/calculator/CalculatorComponent'
import { getExamSeasons } from '../features/calculator/calculatorSlice'
import { LineChart, Line, YAxis, CartesianGrid, ReferenceDot, ResponsiveContainer } from 'recharts';
import Star from "../components/Star";
import { getCurrentUser } from '../features/auth/authSlice'
import { openModal } from '../app/appSlice'


const Calculator = () => {
    const calculatorTitle = "CAO Points Calculator"
    const titleDescription = "Use our CAO Points Calculator to assess your eligibility for specific courses based on your academic achievements and qualifications"
    const examSeasons = _.filter(useSelector(getExamSeasons), s => s.totalPoints > 0)   
    const worstSeasonIndex = _.findIndex(examSeasons, season => season.id === _.get(_.head(_.orderBy(examSeasons, ['totalPoints'])), ['id']))
    const user = useSelector(getCurrentUser)
    const dispatch = useDispatch()
    
    return (
        <Container className="muvr-content">
            <Row className="top-row muvr-row">
                <Col lg={{ span: 8, offset: 2 }}>
                    <div className="title centered-content">
                        <div className="main-title gradient-text">{calculatorTitle}</div>  
                        <div className="title-desc">{titleDescription}</div>
                        {
                            !user &&  
                                <div className="no-user">
                                    You are using the MyCareerVerse calculator as a guest. Your grades will not be saved. Please Login or Register to log your grades and continue your MyCareerverse journey
                                </div>  
                        }
                    </div>  
                </Col>
            </Row>
            <Row className="top-row">
                <Col lg={{ span: 10, offset: 1 }}>
                    <CalculatorComponent />
                </Col>
            </Row>
            {
                _.size(examSeasons) > 0 &&
                    <>
                        <Row className="muvr-row">
                            <Col className="centered-content">
                                {
                                    user &&
                                        <div className="step-link">
                                            <div className="gradient-text">
                                                Continue to the next step - Interest Profiler
                                            </div>
                                            <Button as={Link} to="/interest-profiler" size="lg" variant="outline-primary">
                                                Interest Profiler
                                            </Button>
                                        </div>                                            
                                }
                            </Col>
                        </Row>
                        <Row className="top-row muvr-row">
                            <Col>
                                <div className="lg-title gradient-text centered-content">
                                    POINTS LOGGED
                                </div>
                            </Col>
                        </Row>
                        <Row className="top-row">
                            { 
                                _.map(examSeasons, s => (
                                    <Col key={s.id} className="centered-content"> 
                                        <div className="point-bubble centered-content">
                                            <div className="season">{s.name}</div>
                                            <div className="points">{s.totalPoints + ' points'}</div>
                                        </div>
                                    </Col>
                                ))
                            }               
                        </Row>
                    </>                
            }            
            {
                _.size(examSeasons) > 1 &&
                    <>
                        <Row className="top-row muvr-row">
                            <Col className="centered-content">
                                <div className="avg">
                                    <span className="gradient-text">Your average point score</span>
                                    {parseFloat(_.meanBy(examSeasons, s => s.totalPoints).toFixed(2))}
                                </div>
                            </Col>
                        </Row>            
                        <Row className="top-row">
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                                <div className="chart-holder backdrop">
                                    <div className="chart-title gradient-text">Point Range</div>
                                    <ResponsiveContainer width="100%" aspect={1.5}>
                                        <LineChart data={examSeasons} strokeWidth={0.5}>
                                            <CartesianGrid vertical={false}/>
                                            <ReferenceDot  x={worstSeasonIndex} y={_.get(examSeasons, [worstSeasonIndex, 'totalPoints'])} r={20} shape={<Star />}/>
                                            <YAxis 
                                                type="number" 
                                                domain={[0, 650]} 
                                                ticks={_.range(50, 650, 50)}

                                            />  
                                                                                               
                                            <Line 
                                                type="monotone" 
                                                dataKey="totalPoints" 
                                                stroke="#9747FF" 
                                                strokeWidth={3}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="lowest-points centered-content">
                                    This points-tracking graph for the senior cycle helps you monitor your progress, stay motivated, and make informed decisions about courses and applications. Click below for support to help you improve your grades and move that Star up with Study and Exam techniques
                                </div>
                            </Col>
                        </Row>   
                        {
                            user &&                        
                                <Row className="top-row muvr-row">
                                    <Col xs={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
                                        <div className="study-tips">
                                             <Link to="/support">Click here for Academic Support videos and Study tips</Link>                                                                                           
                                        </div>
                                    </Col>
                                </Row>  
                        }
                    </>                 
            }  
            
            {
                !user &&      
                    <Row className="muvr-row">
                        <Col className="centered-content">
                            <div className="login-bottom">
                                <div>
                                    You are currently a guest user. Please login to start your MyCareerVerse journey
                                </div>
                                <Button variant="outline-primary" onClick={() => dispatch(openModal({modalName: 'login'}))}>Login</Button>
                            </div>                                    
                        </Col>
                    </Row>    
            }             
        </Container>
    )
}

export default Calculator