import { Container, Row, Col, Card, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import InterestProfilerComponent from '../features/interestProfiler/InterestProfilerComponent'
import { getProfile, setProfile } from '../features/interestProfiler/interestProfilerSlice'
import { useGetSimpleUserCareersListQuery, useUpdateUserCareersMutation } from '../features/user/userAPI'
import { useGetInterestProfileQuery, useGetCareersQuery } from '../features/interestProfiler/interestProfilerAPI'
import { ResponsiveContainer, BarChart, YAxis, XAxis, CartesianGrid, Bar, Cell } from 'recharts';
import { useEffect } from 'react'
import RealisticIcon from '../components/categories/RealisticIcon'
import InvestigativeIcon from '../components/categories/InvestigativeIcon'
import ArtisticIcon from '../components/categories/ArtisticIcon'
import SocialIcon from '../components/categories/SocialIcon'
import EnterprisingIcon from '../components/categories/EnterprisingIcon'
import ConventionalIcon from '../components/categories/ConventionalIcon'


const InterestProfiler = () => {

    const dispatch = useDispatch()   
    const title = "Career Interest"
    const interestInfoTitle = "Interest Information"
    const interestProfile = useSelector(getProfile)   
    const savedProfile = _.get(useGetInterestProfileQuery(), ['data', 'interest_profile'])   
    const suggestedCareers = _.get(useGetCareersQuery({}, {skip: !_.get(interestProfile, ['complete'])}), ['data', 'careers'])
    const userCareers = _.get(useGetSimpleUserCareersListQuery({}, {skip:  !_.get(interestProfile, ['complete']) || _.isEmpty(interestProfile)}), ['data', 'careers'])
    const [ updateUserCareers ] = useUpdateUserCareersMutation();     
    const categories = [
        {
            icon: <RealisticIcon />,
            description: 'People who have athletic or mechanical ability, prefer to work with objects, machines, tools, plants or animals, or to be outdoors.',
            color: '#AFDBF3',
            name: 'realistic'
        },
        {
            icon: <InvestigativeIcon />,
            description: 'People who like to observe, learn, investigate, analyze, evaluate or solve problems.',
            color: '#CCCBF0',
            name: 'investigative'
        },
        {
            icon: <ArtisticIcon />,
            description: 'People who have artistic, innovating or intuitional abilities and like to work in unstructured situations using their imagination and creativity.',
            color: '#FAE4CD',
            name: 'artistic'
        },
        {
            icon: <SocialIcon />,
            description: 'People who like to work with people to enlighten, inform, help, train, or cure them, or are skilled with words.',
            color: '#C1F0BC',
            name: 'social'
        },
        {
            icon: <EnterprisingIcon />,
            description: 'People who like to work with people, influencing, persuading, performing, leading or managing for organizational goals or economic gain',
            color: '#FFD3DB',
            name: 'enterprising'
        },
        {
            icon: <ConventionalIcon />,
            description: 'People who like to work with data, have clerical or numerical ability, carry out tasks in detail or follow through on others\' instructions.',
            color: '#B5EFDE',
            name: 'conventional'
        }
    ]

    useEffect(() => {        
        if(savedProfile) {
            dispatch(setProfile(_.omit(savedProfile, ['id', 'user_id'])))
        }
    }, [savedProfile, dispatch]); 

    const handleCareerSelection = (career, event) => {         
        updateUserCareers({
            action: _.get(event, ['target', 'checked']) ? 'add' : 'remove',
            career_id: career.id 
        })       
    }
   
    return (
        <Container className="interest-profiler muvr-content">
            <Row className="top-row muvr-row">
                <Col lg={{ span: 8, offset: 2 }}>
                    <div className="title centered-content">
                        <div className="main-title gradient-text">{title}</div>  
                        <div className="title-desc">
                            John Holland Interest Profiler
                        </div>
                        <div className="title-desc">
                            Discover suitable career options by completing the John Holland Interest Profiler, which evaluates your personal preferences. This is a non-scientific test and to be used only as a discussion tool
                        </div>
                    </div>  
                </Col>
            </Row>  
            <Row className="decorated muvr-row">
                <Col
                    xs={{ span: 10, offset: 1 }} 
                    md={{ span: 8, offset: 2 }}
                    xl={{ span: 6, offset: 3 }} 
                    className="centered-content"
                >
                    <InterestProfilerComponent />                    
                </Col>                
            </Row>             
            {            
                _.get(interestProfile, ['complete']) && 
                    <>
                        <Row className="top-row muvr-row">
                            <Col lg={{ span: 8, offset: 2 }}>
                                <div className="title centered-content">
                                    <div className="main-title gradient-text">{interestInfoTitle}</div>  
                                </div>  
                            </Col>
                        </Row>  
                        <Row className="title-row muvr-row">
                            <Col className="centered-content">
                                <div className="gradient-text best-cat-title">
                                    Your 3 highest scoring categories are:
                                </div>
                            </Col>
                        </Row>
                        <Row className="titled-row muvr-row">
                            {
                                _.map(_.take(_.orderBy(_.map(categories, cat => {
                                                return {
                                                    ...cat,
                                                    score: interestProfile[cat.name]
                                                }
                                            }), 'score', 'desc'), 3), category => (
                                    <Col key={category.name} xs={12} lg={4}>
                                        <Card className={category.name + ' best-cat-card'}>                                                                                       
                                            <Card.Body>
                                                {category.icon}
                                                <div className="title">{_.capitalize(category.name)}</div>
                                                <div className="description">{category.description}</div>
                                            </Card.Body>                                            
                                        </Card>
                                    </Col>
                                ))
                            }                        
                        </Row>
                        <Row className="title-row muvr-row">
                            <Col className="centered-content">
                                <div className="profile-code">
                                    <span className="gradient-text">Your career interest code is </span>
                                    <span>
                                    {
                                        _.map(_.take(_.orderBy(_.map(categories, cat => {
                                                return {
                                                    ...cat,
                                                    score: interestProfile[cat.name]
                                                }
                                            }), 'score', 'desc'), 3), c => _.toUpper(c.name[0]))
                                    }
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="titled-row muvr-row">
                            <Col>
                                <div className="chart-holder backdrop">
                                    <ResponsiveContainer>
                                        <BarChart                                          
                                            data={_.orderBy(_.map(categories, cat => {
                                                return {
                                                    name: _.capitalize(cat.name), 
                                                    score: interestProfile[cat.name]
                                                }
                                            }), 'score', 'desc')}
                                           
                                            barSize={80}
                                        >
                                            <XAxis dataKey={bar => window.innerWidth > 991 ? bar.name : bar.name[0]}/>
                                            <YAxis 
                                                type="number" 
                                                domain={[0, 5]} 
                                                ticks={_.range(0, 6)}                                          
                                            />                                  
                                            <CartesianGrid vertical={false} stroke="#666666"/>
                                            <Bar dataKey="score">
                                                {
                                                    _.map(categories, (cat, index) =>  (
                                                        <Cell 
                                                            key={`cell-${index}`} 
                                                            fill={cat.color} 
                                                        />
                                                    ))
                                                }
                                            </Bar>                                    
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Col>
                        </Row>                        
                        <Row className="top-row muvr-row">
                            <Col className="centered-content">  
                                <div className="career-list">
                                    <div className="career-title" id="careers">
                                        SUGGESTED CAREERS
                                    </div>                                           
                                    <div className="career-subtext">
                                        Select Careers of interest to add to Student Dashboard
                                    </div>
                                    <Form className="career-form">
                                    <Row> 
                                       {
                                            _.map(suggestedCareers, career => (
                                                <Col xs={12} sm={6} md={4} key={career.id}>
                                                    <div className="form-item">                                                         
                                                        <Form.Check                                                                     
                                                            id={career.id}
                                                            label={career.name}
                                                            reverse
                                                            checked={_.indexOf(userCareers, career.id) !== -1 || false}
                                                            onChange={e => handleCareerSelection(career, e)}
                                                          />                                                             
                                                    </div>
                                                </Col>
                                            ))                                               
                                        }             
                                    </Row>
                                    </Form>
                                    <div className="link-holder">
                                        {
                                            !_.isEmpty(userCareers) &&                                   
                                                <Link to="/dashboard" disabled>
                                                    View Career Tab on Student Dashboard
                                                </Link>
                                        }
                                    </div>
                                </div>                   
                            </Col>
                        </Row>                               
                    </>
            }
        </Container>    
    )
  
}

export default InterestProfiler