import _ from 'lodash'

const Star = props => {    
    return (           
        <>
            {
                !_.isEmpty(props) ?       
                    (<svg width="50" height="50" y={props.cy - 26} x={props.cx - 25} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M48.3839 17.8461L33.3949 15.5339L26.6944 1.1154C26.5114 0.720633 26.2103 0.401058 25.8384 0.206807C24.9057 -0.281954 23.7722 0.125347 23.3058 1.1154L16.6053 15.5339L1.61629 17.8461C1.20304 17.9087 0.825219 18.1155 0.535947 18.4288C0.186233 18.8104 -0.00647562 19.3236 0.00016616 19.8559C0.00680794 20.3882 0.212257 20.8958 0.571368 21.2674L11.4161 32.4901L8.854 48.3372C8.79392 48.7059 8.83235 49.085 8.96494 49.4317C9.09753 49.7783 9.31897 50.0786 9.60415 50.2984C9.88934 50.5183 10.2268 50.6489 10.5784 50.6755C10.93 50.7021 11.2815 50.6236 11.5932 50.4489L25.0001 42.9671L38.407 50.4489C38.773 50.6557 39.1981 50.7247 39.6054 50.6495C40.6326 50.4615 41.3233 49.4276 41.1462 48.3372L38.5841 32.4901L49.4289 21.2674C49.724 20.9604 49.9189 20.5593 49.9779 20.1207C50.1373 19.0241 49.4171 18.009 48.3839 17.8461Z" fill="#9747FF"/>
                    </svg>)
                :
                    <svg className="star" width="50" height="50" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M48.3839 17.8461L33.3949 15.5339L26.6944 1.1154C26.5114 0.720633 26.2103 0.401058 25.8384 0.206807C24.9057 -0.281954 23.7722 0.125347 23.3058 1.1154L16.6053 15.5339L1.61629 17.8461C1.20304 17.9087 0.825219 18.1155 0.535947 18.4288C0.186233 18.8104 -0.00647562 19.3236 0.00016616 19.8559C0.00680794 20.3882 0.212257 20.8958 0.571368 21.2674L11.4161 32.4901L8.854 48.3372C8.79392 48.7059 8.83235 49.085 8.96494 49.4317C9.09753 49.7783 9.31897 50.0786 9.60415 50.2984C9.88934 50.5183 10.2268 50.6489 10.5784 50.6755C10.93 50.7021 11.2815 50.6236 11.5932 50.4489L25.0001 42.9671L38.407 50.4489C38.773 50.6557 39.1981 50.7247 39.6054 50.6495C40.6326 50.4615 41.3233 49.4276 41.1462 48.3372L38.5841 32.4901L49.4289 21.2674C49.724 20.9604 49.9189 20.5593 49.9779 20.1207C50.1373 19.0241 49.4171 18.009 48.3839 17.8461Z" fill="#9747FF"/>
                    </svg>
            }
        </>
        
    )
}


export default Star 